import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start_date")}${getParams(
          "end_date"
        )}${getParams("filter")}${getParams("sort_by")}is_active=${
          params?.is_active ? params["is_active"] : 0
        }&sort_order=${params?.sort_order || "desc"}&page_size=${
          params?.page_size || "10"
        }&filter=${params?.filter || ""}&${getParams("search")}page=${
          params?.page || 1
        }`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const contactUs = createApi({
  reducerPath: "contactUsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["contactUs"],
  endpoints: (builder) => ({
    getContactUsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/contacts`,
      }),
      providesTags: ["contactUs"],
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["contactUs"],
    }),
  }),
});

export const {
  useGetContactUsListDataQuery,
  useUpdateTableFieldsDataMutation,
} = contactUs;
