import { useMemo } from "react";
import { useGetPaymentDetailsQuery } from "../../../../store/queries/orders";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { sentPaymentLink } from "../api";
const usePaymentDetailsOrder = () => {
  const { ID } = useParams();
  const orderId = localStorage?.getItem("product_order_id");
  const { data: paymentDetailData, isFetching } = useGetPaymentDetailsQuery({
    order_id: ID ?? orderId,
  });

  const paymentDetailsLabel = {
    payment_id: "Payment ID",
    payment_mode: "Payment Mode",
    payment_type: "Payment Type",
    transaction_id: "Transaction ID",
    status: "Status",
    total_amount: "Total Amount",
    payment_review: "Payment Review",
    transaction_date: "Transaction Date",
    created_by: "Created By",
  };

  const paymentDetailValue = {
    payment_id: `${paymentDetailData?.data?.payment_id ?? "Not specified"}`,
    payment_mode: `${paymentDetailData?.data?.payment_mode ?? "Not specified"}`,
    payment_type: `${paymentDetailData?.data?.payment_type ?? "Not specified"}`,
    transaction_id: `${
      paymentDetailData?.data?.transaction_id ?? "Not specified"
    }`,
    status: `${paymentDetailData?.data?.status ?? "Not specified"}`,
    total_amount: `${paymentDetailData?.data?.total_amount ?? "Not specified"}`,
    payment_review: `${
      paymentDetailData?.data?.payment_review ?? "Not specified"
    }`,
    transaction_date: `${
      paymentDetailData?.data?.transaction_date ?? "Not specified"
    }`,
    created_by: `${paymentDetailData?.data?.created_by ?? "Not specified"}`,
  };

  const paymentValues = Object.keys(paymentDetailValue).map((key) => {
    return {
      label: paymentDetailsLabel[key],
      value:
        typeof paymentDetailValue[key] === "string"
          ? paymentDetailValue?.[key]
          : typeof paymentDetailValue[key] === "number"
          ? paymentDetailValue?.[key]
          : paymentDetailValue?.[key]?.name,
    };
  });
  const handleSendLink = () => {
    if (ID) {
      sentPaymentLink(ID).then((response) => {
        if (response?.status === 200) {
          toast.success("The link has been sent successfully!");
        } else if (response?.status === 422) {
          toast.error("Something went wrong");
        } else if (response?.success === false) {
          toast.error(response?.data?.order_id?.[0]??"Something went wrong");
        } else {
          toast.error("Something went wrong");
        }
      });
    }
  };
  const hasSendLinkPermission = useMemo(() => {
    let permission = paymentDetailData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_send_payment_link")
    );
    return permission?.[0]?.can_send_payment_link ?? 0;
    // eslint-disable-next-line
  }, [paymentDetailData]);
  return { paymentValues, isFetching,hasSendLinkPermission,paymentDetailData,handleSendLink};
};

export default usePaymentDetailsOrder;
