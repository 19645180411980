import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import usePincodeForm from "./usePincodeForm";

const PincodeForm = ({ refetch, closeModal, isStickyFooter }) => {
  const { formik, selectedItemsDetails } = usePincodeForm({
    refetch,
    closeModal,
  });
  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Pincode*"}
        type="text"
        id="pincode"
        name="pincode"
        className={`pro-input lg ${
          formik?.errors?.pincode && formik?.touched?.pincode && "error"
        }`}
        {...formik.getFieldProps("pincode")}
        // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik?.errors?.pincode && formik?.touched?.pincode}
        errorMessage={formik?.errors?.pincode}
      />

      <Input
        label={"Handling Charge*"}
        type="text"
        id="handling_charge"
        name="handling_charge"
        className={`pro-input lg ${
          formik?.errors?.handling_charge &&
          formik?.touched?.handling_charge &&
          "error"
        }`}
        {...formik.getFieldProps("handling_charge")}
        // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={
          formik?.errors?.handling_charge && formik?.touched?.handling_charge
        }
        errorMessage={formik?.errors?.handling_charge}
      />

      <Input
        label={"Area*"}
        type="text"
        id="area"
        name="area"
        className={`pro-input lg ${
          formik?.errors?.area && formik?.touched?.area && "error"
        }`}
        {...formik.getFieldProps("area")}
        // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik?.errors?.area && formik?.touched?.area}
        errorMessage={formik?.errors?.area}
      />

      {/* toggle button start  */}
      <div className="pro-toggle">
        <div className="pro-toggle-box">
          <input
            id="status"
            name="status"
            type="checkbox"
            checked={Boolean(formik?.values?.status) ?? false}
            onChange={(e) => {
              formik?.setFieldValue(
                "status",
                !Boolean(formik.values?.status) ?? 1
              );
            }}
          />
          <span></span>
        </div>
        <label>Status</label>
      </div>
      {/* toggle button end  */}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default PincodeForm;
