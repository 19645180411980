import { useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/dist";
import useRouteUtils from "../../../../utils/hooks/useRouteUtils";
import { useGetOrderSummaryQuery } from "../../../../store/queries/orders";

const useOrderDetailLayout = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const printRef = useRef();
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [show, setShow] = useState(false);
  const userData = JSON.parse(localStorage?.getItem("userDetails"));
  const { orderID, ID } = useParams();
  const globalState = useSelector((state) => state.global);
  const location = useLocation();
  const activeProfile = orderID ?? sessionStorage.getItem("active");
  const currentFilter = localStorage.getItem("currentFilter");

  const { data: orderSummary, isFetching } = useGetOrderSummaryQuery({
    order_id: ID,
  });

  //product detail tab
  const hasProductViewPermission = useMemo(() => {
    let permission = orderSummary?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view_product_details")
    );
    return permission?.[0]?.can_view_product_details ?? 0;
    // eslint-disable-next-line
  }, [orderSummary]);

  //travel detail tab
  const hasTravelDetailViewPermission = useMemo(() => {
    let permission = orderSummary?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view_travel_details")
    );
    return permission?.[0]?.can_view_travel_details ?? 0;
    // eslint-disable-next-line
  }, [orderSummary]);

  //document detail tab
  const hasDocumentDetailViewPermision = useMemo(() => {
    let permission = orderSummary?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view_document_details")
    );
    return permission?.[0]?.can_view_document_details ?? 0;
    // eslint-disable-next-line
  }, [orderSummary]);

  //payment detail tab
  const hasDeliveryDetailViewPermission = useMemo(() => {
    let permission = orderSummary?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view_delivery_details")
    );
    return permission?.[0]?.can_view_delivery_details ?? 0;
    // eslint-disable-next-line
  }, [orderSummary]);

  //payment detail tab
  const hasPaymentDetailViewPermission = useMemo(() => {
    let permission = orderSummary?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view_payment_details")
    );
    return permission?.[0]?.can_view_payment_details ?? 0;
    // eslint-disable-next-line
  }, [orderSummary]);

  const hasActivityLogViewPermission = useMemo(() => {
    let permission = orderSummary?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view_activity_log")
    );
    return permission?.[0]?.can_view_activity_log ?? 0;
    // eslint-disable-next-line
  }, [orderSummary]);

  const navigation = [
    hasProductViewPermission && {
      label: "Product Details",
      title: "Product Details",
      link: `/orders/order-details/${activeProfile}/${ID}${window.location.search}`,
      active: checkIfActiveRoute(
        `/orders/order-details/${activeProfile}/${ID}`,
        true
      ),
    },
    hasTravelDetailViewPermission &&
      currentFilter !== "sell" && {
        label: "Travel Details",
        title: "Travel Details",
        link: `/orders/order-details/travel-details/${activeProfile}/${ID}${window.location.search}`,
        active: checkIfActiveRoute(
          `/orders/order-details/travel-details/${activeProfile}/${ID}`
        ),
      },
    hasDocumentDetailViewPermision &&
      currentFilter !== "sell" && {
        label: `Document Details`,
        title: "Document Details",
        link: `/orders/order-details/document-details/${activeProfile}/${ID}${window.location.search}`,
        active: checkIfActiveRoute(
          `/orders/order-details/document-details/${activeProfile}/${ID}`
        ),
      },
    hasDeliveryDetailViewPermission && {
      label: `Delivery Details`,
      title: "Delivery Details",
      link: `/orders/order-details/delivery-details/${activeProfile}/${ID}${window.location.search}`,
      active: checkIfActiveRoute(
        `/orders/order-details/delivery-details/${activeProfile}/${ID}`
      ),
    },
    hasPaymentDetailViewPermission && {
      label: `Payment Details`,
      title: "Payment Details",
      link: `/orders/order-details/order-payments-details/${activeProfile}/${ID}${window.location.search}`,
      active: checkIfActiveRoute(
        `/orders/order-details/order-payments-details/${activeProfile}/${ID}`
      ),
    },
    hasActivityLogViewPermission && {
      label: `Activity Log`,
      title: "Activity Log",
      link: `/orders/order-details/activity-log/${activeProfile}/${ID}${window.location.search}`,
      active: checkIfActiveRoute(
        `/orders/order-details/activity-log/${activeProfile}/${ID}`
      ),
    },
  ].filter(Boolean);

  // if (localStorage.getItem("currentFilter") === "sell") {
  //   const indexTravel = navigation?.findIndex(
  //     (item) => item.label === "Travel Details"
  //   );
  //   if (indexTravel !== -1) {
  //     navigation.splice(indexTravel, 1);
  //   }
  //   const indexDocument = navigation?.findIndex(
  //     (item) => item.label === "Document Details"
  //   );
  //   if (indexDocument !== -1) {
  //     navigation?.splice(indexDocument, 1);
  //   }
  // }
  const newOrderPermissions = [
    { label: "Product Details" },
    { label: "Travel Details" },
    { label: "Document Details" },
    { label: "Delivery Details" },
    { label: "Payment Details" },
    { label: "Activity Log" },
  ];

  const orderTabPermission = useMemo(() => {
    let menus = newOrderPermissions?.flatMap?.((menu) => [menu.label]);
    return navigation
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });

    //eslint-disable-next-line
  }, [globalState?.order_permissions, location.pathname, navigation]);

  const label = {
    user_id: "User ID",
    name: "Name",
    email: "Email",
    phone: "Phone",
  };

  const value = {
    user_id:
      userData !== null
        ? `${userData?.user_unique_id ?? "Not specified"}`
        : "Not specified",
    name: `${userData?.name ?? "Not specified"}`,
    email: `${userData?.email ?? "Not specified"}`,
    phone: `${
      userData?.phone_number
        ? `${userData?.country_code} ${userData?.phone_number}`
        : "Not specified"
    }`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const orderLabel = {
    order_no: "Order Number",
    order_type: "Order Type",
    order_date: "Order Date",
    forex_inr: "Forex INR",
    handling_charge: "Handling Charge",
    handling_charge_gst: "Handling Charge GST",
    service_cost: "Service Charge (+GST)",
    // email: "Email",
    IGST: "IGST",
    CGST: "CGST",
    SGST: "SGST",
    tcs: "TCS",
    round_off: "Round Off",

    //  total_amount: "Total Amount"
  };
  const orderValue = {
    order_no: `${orderSummary?.data?.order_number ?? "Not specified"}`,
    order_type: `${orderSummary?.data?.order_type_name ?? "Not specified"}`,
    order_date: `${orderSummary?.data?.created_at ?? "Not specified"}`,
    forex_inr: `${orderSummary?.data?.forex_amount ?? "Not specified"}`,
    handling_charge: `${
      orderSummary?.data?.handling_charge !== null
        ? `${orderSummary?.data?.handling_charge}`
        : "Not specified"
    }`,
    handling_charge_gst: `${
      orderSummary?.data?.handling_charge_gst !== null
        ? `${orderSummary?.data?.handling_charge_gst}`
        : "Not specified"
    }`,
    service_cost: `${
      orderSummary?.data?.service_cost !== null
        ? `${orderSummary?.data?.service_cost}`
        : "Not specified"
    }`,

    // email: `${userData?.email ?? "Not specified"}`,
    ...(orderSummary?.data?.gst?.IGST
      ? { IGST: `₹ ${orderSummary?.data?.gst?.IGST ?? "Not specified"}` }
      : {
          CGST: `₹ ${orderSummary?.data?.gst?.CGST ?? "Not specified"}`,
          SGST: `₹ ${orderSummary?.data?.gst?.SGST ?? "Not specified"}`,
        }),
    tcs: `${
      orderSummary?.data?.tcs !== null
        ? `${orderSummary?.data?.tcs}`
        : "Not specified"
    }`,
    round_off: `${
      orderSummary?.data?.round_off
        ? `${orderSummary?.data?.round_off?.type} ${orderSummary?.data?.round_off?.amount}`
        : "Not specified"
    }`,

    // total_amount: `${orderDetails?.total_amount ?? "Not specified"}`
  };

  const Details = Object.keys(orderValue).map((key) => {
    return {
      label: orderLabel[key],
      value:
        typeof orderValue[key] === "string"
          ? orderValue?.[key]
          : typeof orderValue[key] === "number"
          ? orderValue?.[key]
          : orderValue?.[key]?.name,
    };
  });

  return {
    show,
    activeProfile,
    isFetching,
    printRef,
    // profileData,
    basicDetails,
    Details,
    showDeleteImageModal,
    navigation: orderTabPermission,
    userData,
    orderSummary,
    setShowDeleteImageModal,
    setShow,
    // refetch,
  };
};

export default useOrderDetailLayout;
