import { getDocument } from "pdfjs-dist";

export const generatePdfThumbnail = async (pdfUrl) => {
  try {
    // Fetch the PDF
    const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());

    // Load the PDF document
    const loadingTask = getDocument({ data: pdfBytes });
    const pdf = await loadingTask.promise;
    const pdfPage = await pdf.getPage(1);

    const scale = 1.5;
    const viewport = pdfPage.getViewport({ scale });

    // Prepare canvas using pdfPage dimensions
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context
    const renderContext = {
      canvasContext: context,
      viewport,
    };
    await pdfPage.render(renderContext).promise;

    // Get the thumbnail image URL
    return canvas.toDataURL();
  } catch (error) {
    
    return null;
  }
};
