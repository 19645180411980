import { getAxiosInstance } from "../../../../../api";

export const documentUpload = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/orders/upload/document`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const documentSubmit = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/orders/add/docment-details`, body,{});
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const documentRemove = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/orders/document/delete?document_id=${id}`)
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
