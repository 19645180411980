import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
     () =>
          async ({ params, endpoint, method, body }) => {

               const api = await getAxiosInstance();
               const getParams = (key) => {

                    if (params?.[key]) {
                         return `${key}=${params[key]}&`;
                    } else {
                         return "";
                    }
               };

               try {
                    const response = await api[method](
                         `${endpoint}?${getParams("start_date")}${getParams(
                              "end_date"
                         )}${getParams("filter")}${getParams("sort_by")}is_active=${params?.is_active ? params["is_active"] : 0
                         }&sort_order=${params?.sort_order || "desc"
                         }&per_page=${params?.per_page || "10"}&${getParams("search")}page=${params?.page || 1
                         }`,
                         body
                    );

                    return { data: response.data };
               } catch (axiosError) {
                    let err = axiosError;

                    return {
                         data: err?.response?.data || err.message,
                    };
               }
          };

          export const global = createApi({
               reducerPath:'globalApi',
               baseQuery:axiosBaseQuery(),
               tagTypes:['basic'],
               endpoints:(builder)=>({
                    getBasicData: builder.query({
                         query: () => ({
                              method: "get",
                              endpoint: `/admin/basic-data`
                         }),
                         providesTags: ['basic'],
                    }),
               })
          })

          export const {useGetBasicDataQuery} = global;
