import { useEffect, useRef, useState } from "react";
import { getInvoiceData } from "./api";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/orders/orderSlice";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import html2pdf from "html2pdf.js";

const useInvoice = () => {
  const [invoice, setInvoice] = useState();
  const { orderId } = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.global);
  const invoiceRef = useRef(null);

  useEffect(() => {
    getInvoiceData(orderId).then((response) => {
      if (response) {
        dispatch(
          updateConfig((state) => {
            state.invoiceLoading = false;
          })
        );
      }
      setInvoice(response?.data?.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownloadClick = () => {
    const element = invoiceRef.current;

    html2pdf(element, {
      margin: [0, 0, 10, 0],
      filename: `${
        invoice?.order_id +
        "_" +
        moment(Date.now()).format("_MM_DD_YYYY_HH_mm_ss")
      }.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: {
        width: 800,
        dpi: 100,
        letterRendering: true,
        useCORS: true,
        logging: true,
        scale: 4,
        scrollY: 0,
      },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      pagebreak: { avoid: "", mode: ["avoid-all", "css", "legacy"] },
      DisablePdfCompression: true,
    });
  };

  const handlePrintClick = useReactToPrint({
    content: () => invoiceRef.current,
  });

  return { invoice,globalState, handlePrintClick, handleDownloadClick, invoiceRef };
};

export default useInvoice;
