import React from "react";

import Style from "./formTabs.module.scss";

export const FormTabs = ({
  tabs,
  children,
  active,
  handleClick,
  isScroll = false,
}) => {
  return (
    <div className={`${Style.root} pro-py-3`}>
      <div className={`${Style.root_inner}`}>
        <div className={`${Style.root_progress} pro-pb-4`}>
          <div className={`${Style.root_progress_inner} ${isScroll ? Style.scrollable : ""}`}>
  {tabs &&
    tabs.map((tab, index) => (
      <div
        className={`${Style.root_items}`}
        onClick={() => handleClick && handleClick(tab)}
        key={index}
      >
          <div
            className={`${Style.item} ${
              tab?.label === active ? Style.active : ""
            } pro-p-2`}
          >
            <p className={`pro-font-xs pro-fw-medium pro-mb-2`}>
              {tab?.label}
            </p>
            {tab?.completed !== "not" && (
              <span className={Style.item_progress_container}>
                <span
                  className={Style.item_progress_bar}
                  style={{ width: `${tab?.completed}%` }}
                ></span>
              </span>
            )}
                    
          </div>
      </div>
    ))}
</div>
        </div>
        {children}
      </div>
    </div>
  );
};
