import { useRef } from "react";
import { Outlet } from "react-router-dom";
import Style from "./Auth.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../assets/Assets";

const AuthModule = () => {
  const emailRef = useRef("");

  return (
    <div className={Style.root}>
      <div className={Style.root_inner}>
        <div className={`${Style.logoWrapper} pro-pb-5`}>
          <Image
            src={Assets.HEADERLOGO}
            width={120.89}
            height={48}
            alt={`Frameley Logo`}
          />
        </div>
        <div className={`${Style.auth} pro-pt-3`}>
          <Outlet context={{ emailRef }} />
        </div>
      </div>
    </div>
  );
};

export default AuthModule;
