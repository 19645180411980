import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const branchIdParams = params?.branch_id
      ? params.branch_id.map((id, i) => `branch_id[${i}]=${id}`).join("&")
      : "";

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start")}${getParams("end")}${getParams(
          "filter"
        )}${getParams("sort_by")}is_active=${
          params?.is_active ? params["is_active"] : 0
        }&sort_order=${params?.sort_order || "desc"}&page_size=${
          params?.page_size || "10"
        }&${getParams("search")}&${branchIdParams}&page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const payment = createApi({
  reducerPath: "paymentApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["payment"],
  endpoints: (builder) => ({
    getPaymentListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/payments`,
      }),
      providesTags: ["payment"],
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/user/fields/update`,
      }),

      invalidatesTags: ["payment"],
    }),
  }),
});

export const { useGetPaymentListDataQuery, useUpdateTableFieldsDataMutation } =
  payment;
