import { getAxiosInstance } from "../../../api";

export const updateGeneralEnquiry = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      "admin/enquiry/general-enquiry-status-change",
      body
    );

    return response;
  } catch (error) {
    return error.response.data;
  }
};
