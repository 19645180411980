import { useEffect, useState } from "react";
import { useGetDashboardDataQuery } from "../../../store/queries/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import Orders from "../../Orders/orderListing";
import Users from "../../Users/user";
import Payments from "../../Payment/payments";
import { updateConfig } from "../../../store/slices/Dashboard/dashboardSlice";
import { getFormatedDate } from "../../../utils/functions/table";

const useDashboardListing = () => {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboard);
  const [date, setDate] = useState({
    startDate: dashboardState.start,
    endDate: dashboardState.end,
    key: "selection",
  });

  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetDashboardDataQuery({
    from_date: getFormatedDate(dashboardState?.start),
    to_date: getFormatedDate(dashboardState?.end),
  });

  const [listingType, setListingType] = useState();
  useEffect(() => {
    if (isSuccess) {
      setListingType(
        Object.keys(dashboardData?.data?.cards?.[0] || {})?.[0] ?? ""
      );
    }

    //eslint-disable-next-line
  }, [isSuccess]);

  const handleTotalOrders = () => {
    setListingType("total_orders");
  };

  const handleUsers = () => {
    setListingType("total_users");
  };

  const handlePayments = () => {
    setListingType("converted_payments");
  };
  const handleCompletedOrders = () => {
    setListingType("completed_orders");
  };
  const handleBuyOrders = () => {
    setListingType("buy_orders");
  };
  const handleSellOrders = () => {
    setListingType("sell_orders");
  };

  let object = {
    total_orders: handleTotalOrders,
    total_users: handleUsers,
    completed_orders: handleCompletedOrders,
    buy_orders: handleBuyOrders,
    sell_orders: handleSellOrders,
    converted_payments: handlePayments,
  };

  const handleClick = (label) => {
    object[label]();
  };

  let dashboardItems = {
    total_orders: {
      label: "total_orders",
      title: "Total Orders",
      icon: "person_raised_hand",
      handleClick,
      component: <Orders dashboard={true} />,
      isDualValue: false,
    },
    completed_orders: {
      label: "completed_orders",
      title: "Completed Orders",
      icon: "person_raised_hand",
      handleClick,
      component: <Orders dashboard={true} subFilter={"completed"} />,
      isDualValue: false,
    },
    buy_orders: {
      label: "buy_orders",
      title: "Buy Orders",
      icon: "person_raised_hand",
      handleClick,
      component: <Orders dashboard={true} mainFilter={"buy"} />,
      isDualValue: false,
    },
    sell_orders: {
      label: "sell_orders",
      title: "Sell Orders",
      icon: "person_raised_hand",
      handleClick,
      component: <Orders dashboard={true} mainFilter={"sell"} />,
      isDualValue: false,
    },
    total_users: {
      label: "total_users",
      title: "Total Users",
      icon: "person",
      handleClick,
      component: <Users dashboard={true} />,
      isDualValue: false,
    },
    converted_payments: {
      label: "converted_payments",
      title: "Settled Payments",
      icon: "payments",
      handleClick,
      component: <Payments dashboard={true} />,
      isDualValue: true,
    },
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.start = startDate;
        state.end = endDate;
      })
    );
  };

  return {
    dashboardItems,
    dashboardData,
    isFetching,
    children: dashboardItems[listingType]?.component,
    listingType,
    date,
    totalValue: dashboardData?.data?.cards[1]?.payments,
    handleDateRangeChange,
  };
};

export default useDashboardListing;
