import { getAxiosInstance } from "../../api";

export const login = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/auth/login", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const logout = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/logout", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const forgotPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("v1/auth/forgot-password", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const resetPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/user/change/password`,
      params
    );

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const changePassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/user/reset/password`,
      params
    );

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const validateEmail = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/user/forgot/password", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const validateOTP = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/user/verify/otp`,
      params
    );

    return response;
  } catch (error) {
    return error.response.data;
  }
};
