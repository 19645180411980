import { useMemo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global/index.js";
import { addDays } from "date-fns";
import {
  updateConfig,
} from "../../../store/slices/ActivityLog/activityLogSlice.js";
import {
  useGetListDataQuery,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/activityLog/index.js";
import { useGetBasicDataQuery } from "../../../store/queries/global/index.js";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Style from "./activityLog.module.scss";
import Assets from "../../../assets/Assets.js";

const useActivityLog = (dashboard) => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.activityLog);

  // const { showCreateModal, selctedId } = useSelector((state) => state.activityLog);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [selectedOption, setSelectedOption] = useState(null);
  const { data: formData = {} } = useGetBasicDataQuery();
  const [is_Editable, setIsEditable] = useState(false);

  const tableRef = useRef(null);

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    page_size: menuState.currentPageSize,
    page: menuState.currentPage,
  });

  //clearing all the slice states initially
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.scrollPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.currentBranchFilter = [];
        state.clearSelection = false;
        state.queryData = {};
        state.queryStatus = "idle";
        state.sortBy = "";
        state.sortOrder = "desc";
        state.search = "";
        state.showCreateModal = false;
        state.selectedId = "";
        state.selectedItemsDetails = "";
        state.is_edit = false;
        state.showViewModal = false;
      })
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  // const [updateStatus] = useUpdateStatusByIdMutation();

  const hasUpdatePermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasCreatePermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
  }, [mainData]);

  const hasDeletePermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permission?.[0]?.can_delete ?? 0;
  }, [mainData]);

  const hasStatusUpdatePermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );
    return permission?.[0]?.can_change_status ?? 0;
  }, [mainData]);

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  // const handleCreateClick = () => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.selectedItemsDetails = "";
  //     })
  //   );

  //   dispatch(
  //     updateConfig((state) => {
  //       state.showCreateModal = true;
  //     })
  //   );
  // };
  // const closeModal = () => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.showCreateModal = false;
  //       state.is_edit = false;
  //       // state.selectedItemsDetails = ""
  //     })
  //   );
  // };

  // const handleEditAction = (data) => {
  //   const tempResponse = mainData?.data?.data?.data.find((item) => {
  //     return item.staff_id === data?.[0];
  //   });
  //   const tempGender = formData?.data?.gender.find(
  //     (item) => item?.id === tempResponse.gender.id
  //   );
  //   const tempResponseData = {
  //     branch_details: tempResponse.branch_details,
  //     name: tempResponse?.name,
  //     phone_number: tempResponse?.phone_number,
  //     email: tempResponse?.email,
  //     country_code: tempResponse?.country_code,
  //     role_details: tempResponse?.role_details,
  //     profile_image: tempResponse?.profile_image,
  //     profile_image_name: tempResponse?.profile_image_name,
  //     gender: tempGender,
  //   };

  //   dispatch(
  //     updateConfig((state) => {
  //       state.selectedItemsDetails = tempResponseData;
  //       // state.selectedItemsDetails = tempGender;
  //       state.showCreateModal = true;
  //       state.is_edit = true;
  //       state.selectedId = data?.[0];
  //     })
  //   );
  // };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  // const handleDelete = async (ids) => {
  //   setShowDeleteConfirm(true);
  //   setSelectedItemID(ids);
  // };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      gender: (field, data) => {
        return <p className="pro-mb-0">{data[field].value}</p>;
      },
      name: (field, data) => {
        return (
          <>
            <div className={`pro-d-flex ${Style.avatar_container}`}>
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data?.profile_image ?? Assets.GENDER_IMAGE}
                  width={16}
                  height={16}
                  alt={data?.staff?.name}
                />
              </div>
              <span className="pro-ms-1 pro-align-self-center">
                {data?.name}
              </span>
            </div>
          </>
        );
      },
      // status: (field, data) => {
      //   const isChecked = data?.status === "Active" ? true : false;

      //   return data?.status === "Active" || data?.status === "Inactive" ? (
      //     <div className="pro-toggle">
      //       <div className="pro-toggle-box">
      //         <input
      //           id="status"
      //           name="status"
      //           type="checkbox"
      //           checked={isChecked}
      //           onChange={() =>
      //             hasStatusUpdatePermission
      //               ? toggleStatusById(isChecked, data?.staff_id)
      //               : undefined
      //           }
      //           disabled={!hasStatusUpdatePermission}
      //         />
      //         <span></span>
      //       </div>
      //     </div>
      //   ) : (
      //     <span className="pro-badge badge-danger-outline">{data?.status}</span>
      //   );
      // },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  // const toggleStatusById = (status, id) => {
  //   let formData = new FormData();
  //   formData.append("_method", "PUT");
  //   formData.append("staff_id", id);
  //   formData.append("status", status === true ? 0 : 1);

  //   updateStatus(formData);
  //   dispatch(
  //     staffs.util.updateQueryData(
  //       "getListData",
  //       {
  //         sort_by: menuState.sortBy,
  //         sort_order: menuState.sortOrder,
  //         search: menuState.search,
  //         page_size: menuState.currentPageSize,
  //         page: menuState.currentPage,
  //         is_active: menuState.is_active,
  //       },
  //       (cacheEntry) => {
  //         let currentCache = { ...current(cacheEntry) };
  //         const newResult = currentCache.data.data?.data?.map((res) =>
  //           res.staff_id === id
  //             ? {
  //                 ...res,
  //                 status: status === 1 ? "Inactive" : "Active",
  //               }
  //             : res
  //         );
  //         currentCache = {
  //           ...currentCache,
  //           data: {
  //             ...currentCache.data,
  //             data: {
  //               ...currentCache.data.data,
  //               data: newResult,
  //             },
  //           },
  //         };
  //         return currentCache;
  //       }
  //     )
  //   );
  // };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
      })
    );
  };

  // const handleDashboardRedirect = (filter) => {
  //   navigate("/branches");
  // };

  // const handleChange = (selectedOption) => {
  //   setSelectedOption(selectedOption);
  //   dispatch(
  //     updateConfig((state) => {
  //       state.is_active = selectedOption.value;
  //     })
  //   );
  // };

  // const handleDeleteAction = async () => {
  //   if (Array.isArray(selectedItemID) && showDeleteConfirm) {
  //     dispatch(deleteData(selectedItemID[0]))
  //       .unwrap()
  //       .then((result) => {
  //         if (result?.message === "Success") {
  //           toast.success(result?.message);
  //         } else {
  //           toast.error(result?.message);
  //         }
  //         if (result) {
  //           refetch();
  //           setSelectedItemID("");
  //           setShowDeleteConfirm(false);
  //         }
  //       })
  //       .catch((err) => {
  //         setShowDeleteConfirm(false);
  //       });
  //   } else {
  //     setShowDeleteConfirm(false);
  //   }
  // };

  const checkIsActive = (id) => {
    if (Array.isArray(id)) {
      let isStaffExist = mainData?.data?.data?.data?.filter(
        (row) => row.staff_id === id[0]
      );
      if (isStaffExist.length > 0 && isStaffExist[0]["status"] === "Deleted") {
        setIsEditable(true);
        return;
      } else {
        setIsEditable(false);
      }
    }
  };

  return {
    is_Editable,
    mainData,
    menuState,
    // showCreateModal,
    isFetching,
    isLoading,
    date,
    showEditModal,
    tableFields,
    paginationOptions,
    // selctedId,
    actionOptions,
    hasCreatePermission,
    hasUpdatePermission,
    hasDeletePermission,
    selectedOption,
    tableRef,
    formData,
    currentPage: menuState.currentPage,
    checkIsActive,
    // handleEditAction,
    // handleDashboardRedirect,
    handleActionChange,
    refetch,
    updateTableFields,
    handleSort,
    handleSearch,
    // handleCreateClick,
    // closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handlePagination,
    handlePageSize,
    handleClearClick,
    // handleChange,
    // handleDelete,
    // setShowDeleteConfirm,
    // handleDeleteAction,
  };
};

export default useActivityLog;
