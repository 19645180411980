import { IconText } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Assets from "../../../assets/Assets";
import Style from "./roles.module.scss";

const iconObj = {
  approved: Assets.GREENCHECK,
  partial: Assets.YELLOWCHECK,
  empty: Assets.REDCHECK,
};
export const Dashboard = ({ data, onClick }) => {
  if (!data?.dashboard) {
    return "";
  }
  return (
    <IconText
      propStyle={{ root: Style.root, rootIcon: Style.rootIcon }}
      icon={
        <img
          src={data?.dashboard ? iconObj[`${data?.dashboard}`] : ""}
          alt={"img"}
        />
      }
    />
  );
};

export const UserRow = ({ data, onClick }) => {
  if (!data?.user) {
    return "";
  }
  return (
    <IconText
      propStyle={{ root: Style.root, rootIcon: Style.rootIcon }}
      icon={
        <img src={data?.user ? iconObj[`${data?.user}`] : ""} alt={"img"} />
      }
    />
  );
};

export const OrderIcon = ({ data, onClick }) => {
  if (!data?.order) {
    return "";
  }
  return (
    <IconText
      propStyle={{ root: Style.root, rootIcon: Style.rootIcon }}
      icon={
        <img src={data?.order ? iconObj[`${data?.order}`] : ""} alt={"img"} />
      }
    />
  );
};

export const BranchIcon = ({ data, onClick }) => {
  if (!data?.branch) {
    return "";
  }
  return (
    <IconText
      propStyle={{ root: Style.root, rootIcon: Style.rootIcon }}
      icon={
        <img src={data?.branch ? iconObj[`${data?.branch}`] : ""} alt={"img"} />
      }
    />
  );
};

export const SettingsIcon = ({ data, onClick }) => {
  if (!data?.configure) {
    return "";
  }
  return (
    <IconText
      propStyle={{ root: Style.root, rootIcon: Style.rootIcon }}
      icon={
        <img
          src={data?.configure ? iconObj[`${data?.configure}`] : ""}
          alt={"img"}
        />
      }
    />
  );
};

export const PaymentIcon = ({ data, onClick }) => {
  if (!data?.payment) {
    return "";
  }
  return (
    <IconText
      propStyle={{ root: Style.root, rootIcon: Style.rootIcon }}
      icon={
        <img
          src={data?.payment ? iconObj[`${data?.payment}`] : ""}
          alt={"img"}
        />
      }
    />
  );
};

// export const NotificationIcon = ({ data, onClick }) => {
//   if (!data?.support) {
//     return "";
//   }
//   return <IconText icon={<img src={data?.support ? iconObj[`${data?.support}`] : ""} alt={"img"} />} />;
// };
