import { useFormik } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  updateConfig,
  createNewEntry,
  updateData,
} from "../../../../store/slices/Delivery/deliverySlice.js";

const useDeliveryForm = ({ refetch, closeModal }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);

  const { is_edit,selectedId, selectedItemsDetails } = useSelector((state) => state.delivery);

  const validation = Yup.object({
    name: Yup.string()
      .min(2, "The name must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .required("*Required"),
    
  });

  const formik = useFormik({
    initialValues: {
      delivery_type_id: selectedItemsDetails !== ""
      ? selectedItemsDetails.delivery_type_id
      : "",
      name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.name
          : "",
      
          status:selectedItemsDetails !== "" ? selectedItemsDetails?.status !== null ? selectedItemsDetails?.status : 0 : 0,
      
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        delivery_type_id: is_edit ? selectedId : "",
        name: values.name,
        status: +values.status,
      };
      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });

      if (selectedItemsDetails !== "") {
        formData.append("_method", "PUT");
        dispatch(
          updateData({data: formData })
        ).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createNewEntry(formData)).then((response) => {
          if (response?.payload?.success) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (!response?.payload?.success) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  return {
    formik,
    selectedItemsDetails,
    profilefileInputRef,
    handleCloseModal,
  };
};

export default useDeliveryForm;
