import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start_date")}${getParams(
          "end_date"
        )}${getParams("filter")}${getParams("sort_by")}is_active=${
          params?.is_active ? params["is_active"] : 0
        }&sort_order=${params?.sort_order || "desc"}&page_size=${
          params?.page_size || "10"
        }&${getParams("search")}page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const bankDetails = createApi({
  reducerPath: "bankDetailsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["bankDetails"],
  endpoints: (builder) => ({
    getBankDetailsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/bank-account/list`,
      }),
      providesTags: ["bankDetails"],
    }),
  }),
});

export const { useGetBankDetailsDataQuery } = bankDetails;
