import { getAxiosInstance } from "../../../api";

export const updateContactUsForm = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put("admin/contacts/change/status", body);

    return response;
  } catch (error) {
    return error.response.data;
  }
};
