import { useSelector, useDispatch } from "react-redux";
import {
  useGetEnquiryListDataQuery,
  useUpdateStatusChangeMutation,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/callback";
import { updateConfig } from "../../../store/slices/Callback/callbackSlice";
import Select from "react-select";
import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";

const useCallback = () => {
  const dispatch = useDispatch();
  const callbackState = useSelector((state) => state.callback);
  const { showEditModal } = useSelector((state) => state.global);

  const {
    data: callbackData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetEnquiryListDataQuery({
    sort_by: callbackState.sortBy,
    sort_order: callbackState.sortOrder,
    search: callbackState.search,
    page_size: callbackState.currentPageSize,
    page: callbackState.currentPage,
    filter: callbackState.currentFilter,
  });

  const statusOptions = [
    {
      label: "Pending",
      value: "1",
    },
    {
      label: "Processing",
      value: "2",
    },
    {
      label: "Completed",
      value: "3",
    },
  ];

  const filterOptions = [
    {
      name: "All",
      value: "",
    },
    {
      name: "Pending",
      value: 1,
    },
    {
      name: "Processing",
      value: 2,
    },
    {
      name: "Completed",
      value: 3,
    },
  ];

  const [updateStatusChange] = useUpdateStatusChangeMutation();
  const [updateTableFields] = useUpdateTableFieldsDataMutation();

  //clearing all the slice states initially
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.scrollPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.currentBranchFilter = [];
        state.clearSelection = false;
        state.queryData = {};
        state.queryStatus = "idle";
        state.sortBy = "";
        state.sortOrder = "desc";
        state.search = "";
        state.showCreateModal = false;
        state.selectedId = "";
        state.selectedItemsDetails = "";
        state.is_edit = false;
        state.showViewModal = false;
      })
    );
    //eslint-disable-next-line
  }, []);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handleSort = (label) => {
    if (callbackState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = callbackState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const hasChangeStatusPermission = useMemo(() => {
    let permission = callbackData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );
    return permission?.[0]?.can_change_status ?? 0;
    // eslint-disable-next-line
  }, [callbackData]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (field, data) => {
        let selectVal = statusOptions.find(
          (item) => item.label === data[field]
        );

        return hasChangeStatusPermission ? (
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input lg`}
            classNamePrefix="pro-input"
            options={statusOptions?.filter(
              (item) =>
                item.value > selectVal.value &&
                item.value < +selectVal.value + 2
            )}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            value={selectVal}
            isDisabled={selectVal?.value === "3"}
            onChange={(selectedOption) => {
              let formData = new FormData();
              //     formData.append("_method", "PUT");
              formData.append("enquiry_id", data?._id);
              formData.append("status", selectedOption?.value);

              updateStatusChange(formData)
                .then((response) => {
                  if (response?.data?.success) {
                    toast.success("Status Updated Successfully!");
                    refetch?.();
                  } else if (!response?.data?.success) {
                    toast.error("Failed to Update Status!");
                  } else {
                    toast.error("Failed to Update Status!");
                  }
                })
                .catch(() => {
                  toast.error("Failed to Update Status!");
                });
            }}
            menuPlacement="auto"
            menuPosition="fixed"
          />
        ) : (
          <p>{data[field]}</p>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const filters = useMemo(() => {
    if (filterOptions) {
      const buttonGroups = filterOptions?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, []);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
      localStorage.setItem("currentFilter", filter?.value);
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
      localStorage.setItem("currentFilter", filter.value);
    }
  };

  return {
    callbackData,
    callbackState,
    paginationOptions,
    isLoading,
    isFetching,
    actionOptions,
    showEditModal,
    filters,
    activeFilter: callbackState?.currentFilter,
    handleFilter,
    updateTableFields,
    refetch,
    closeEditModal,
    handleActionChange,
    handleClearClick,
    handleSort,
    handlePageSize,
    handlePagination,
    getRow,
    handleSearch,
  };
};

export default useCallback;
