import { useDispatch, useSelector } from "react-redux";
// import { useGetRateEditDataQuery } from "../../../../../store/queries/branches";
import { updateFormData } from "../../../../../store/slices/Branches/branchRateFormSlice";
import { useEffect } from "react";

const useBranchRateForm = () => {
  //   const [params] = useSearchParams();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const { activeTab, CompletedPercentage, completedTabs, webId } = useSelector(
    (state) => state.branchRateForm
  );

  useEffect(() => {
    dispatch(
      updateFormData((state) => {
        state.activeTab = "Buy";
      })
    );
    //eslint-disable-next-line
  }, []);

  let tabs = [
    { label: "Buy", completed: CompletedPercentage.Buy },
    { label: "Sell", completed: CompletedPercentage.Sale },
  ];

  const handleTabClick = (tab) => {
    dispatch(
      updateFormData((state) => {
        state.activeTab = tab.label;
      })
    );
  };

  return {
    tabs,
    activeTab,
    webId,
    handleTabClick,
  };
};

export default useBranchRateForm;
