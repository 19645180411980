import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../Global/OrderColumn";
import useUser from "./useUser";
import { FaSort } from "react-icons/fa";
import EmptyData from "../../Global/EmptyData";
import UserForm from "./UserForm";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import Style from "./user.module.scss";

const UserAdmin = ({ dashboard = false }) => {
  const {
    mainData,
    menuState,
    actionOptions,
    isLoading,
    hasEditPermission,
    hasViewPermission,
    isFetching,
    handleDateChange,
    currentPage,
    formData,
    showEditModal,
    showCreateModal,
    paginationOptions,
    activeFilter,
    date,
    hasCreatePermission,
    handleDashboardRedirect,
    handleDateRangeChange,
    handleFilter,
    updateTableFields,
    closeEditModal,
    handleSearch,
    handleEditClick,
    handleActionChange,
    handleClearClick,
    getRow,
    handleSort,
    handleEditAction,
    handlePagination,
    handlePageSize,
    closeModal,
    refetch,
    handleCreateClick,
    filters,
  } = useUser({ dashboard });
  return (
    <>
      {!dashboard && <HeadingGroup title={"User"} className={`pro-mb-4`} 
          buttonTitle={hasCreatePermission === 1 ? "Create" : ""}
          handleClick={handleCreateClick}
          />}

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          {!dashboard && (
            <div className="col">
              <SearchFilters
                data={filters}
                onDateChange={handleDateChange}
                onSearchInput={handleSearch}
                showActions={false}
                handleActionClick={handleEditClick}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                actionOptions={
                  !dashboard
                    ? actionOptions?.filter((item) => item.value === 0)
                    : ""
                }
                onActionOptionChange={handleActionChange}
                handleClear={handleClearClick}
                searchInputProps={{ value: menuState?.search }}
                activeFilter={activeFilter}
                handleButtonGroupChange={handleFilter}
                dropDownFilter={
                  <div className="col-auto">
                    <CustomDateRangePicker
                      defaultDateRange={date}
                      handleDateRangeChange={handleDateRangeChange}
                    />
                  </div>
                }
                initialDateRange={date}
              />
            </div>
          )}
        </div>
        {mainData?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <div
            onClick={dashboard ? () => handleDashboardRedirect() : () => {}}
            className={!dashboard ? "pro-pt-3" : `${Style.dashboardTable}`}
          >
            <Table
              data={mainData?.data?.data?.data || []}
              uniqueID={"user_id"}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              showCheckBox={
                hasViewPermission === 1 && !dashboard ? true : false
              }
              deletable={false}
              handleEdit={handleEditAction}
              clear={menuState.clearSelection}
              multiSelect={false}
              assignable={false}
              fields={mainData?.data?.fields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={menuState?.currentPageSize}
            />
          </div>
        )}

        {mainData?.data?.data?.data?.length > 0 && !dashboard && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.data?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <OffCanvasLayout
          show={showCreateModal}
          handleClose={closeModal}
          // title={"Add Branch"}
          title={menuState?.is_edit ? "Update User" : "Add User"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <UserForm
            refetch={refetch}
            closeModal={closeModal}
            formData={formData?.data}
            hasEditPermission={hasEditPermission}
          />
        </OffCanvasLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.fields ?? {}).reduce(
                (filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.fields[property];
                  return filteredObj;
                },
                {}
              )}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default UserAdmin;
