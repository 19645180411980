import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const branchIdParams = params?.branch_id
      ? params.branch_id.map((id) => `branch_id[]=${id}`).join("&")
      : "";

    try {
      const response = await api[method](
        `${endpoint}?${getParams("order_id")}${getParams("start")}${getParams(
          "end"
        )}${getParams("filter")}${getParams("sort_by")}${getParams(
          "product_sort_by"
        )}is_active=${params?.is_active ? params["is_active"] : 0}&sort_order=${
          params?.sort_order
            ? params?.sort_order
            : (params?.product_sort_order
                ? params?.product_sort_order
                : "desc") || "desc"
        }&page_size=${params?.page_size || "10"}&${getParams(
          "search"
        )}&${branchIdParams}&page=${params?.page || 1}&${getParams(
          "main_status"
        )}&${getParams("sub_status")}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const orders = createApi({
  reducerPath: "ordersApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "orders",
    "product",
    "document",
    "travel",
    "delivery",
    "payment",
    "OrderNoteHistory",
    "OrderSummary",
    "PaymentSummary",
  ],
  endpoints: (builder) => ({
    getOrderListeData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/orders`,
      }),
      providesTags: ["orders"],
    }),
    getDocumentListeData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/orders/document`,
      }),
      // keepUnusedDataFor: 0,
      providesTags: ["document"],
    }),
    getProductListeData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/orders/product/list`,
      }),
      // keepUnusedDataFor: 0,
      providesTags: ["product"],
    }),
    getActivityLogData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/orders/activity-log`,
      }),
      // keepUnusedDataFor: 0,
      providesTags: ["activity"],
    }),
    getTravelDetails: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/orders/travel-details/view`,
      }),
      // keepUnusedDataFor: 0,
      providesTags: ["travel"],
    }),
    getDeliveryDetails: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/orders/delivery/view`,
      }),
      // keepUnusedDataFor: 0,
      providesTags: ["delivery"],
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/user/fields/update`,
      }),

      invalidatesTags: ["orders"],
    }),
    updateStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/orders/status/change`,
      }),
      invalidatesTags: ["orders"],
    }),
    getPaymentDetails: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/orders/payment-details/view`,
      }),
      providesTags: ["payment"],
    }),

    //order note history
    getOrderNoteHistory: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/order/note/view`,
      }),
      providesTags: ["OrderNoteHistory"],
    }),

    //order summary
    getOrderSummary: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/orders/view`,
      }),
      providesTags: ["OrderSummary"],
    }),

    //payment summary
    getPaymentSummary: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/orders/payment-summary`,
      }),
      providesTags: ["PaymentSummary"],
    }),
  }),
});

export const {
  useGetOrderListeDataQuery,
  useUpdateTableFieldsDataMutation,
  useGetTravelDetailsQuery,
  useGetDocumentListeDataQuery,
  useGetProductListeDataQuery,
  useGetDeliveryDetailsQuery,
  useUpdateStatusMutation,
  useGetPaymentDetailsQuery,
  useGetOrderNoteHistoryQuery,
  useGetOrderSummaryQuery,
  useGetPaymentSummaryQuery,
  useGetActivityLogDataQuery,
} = orders;
