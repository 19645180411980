import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useDeliveryDetails from "./useDeliveryDetails";

const DeliveryDetails = ({
  // formik,
  refetch,
  providersState,
  // handleCloseModal,
  handleClose,
  status,
  setStatus,
  hideStep,
}) => {
  const { formik, orderBasicData,itemData, getFieldError,pinMessage,handleCharge,handlePincodeChange,
    setHideDetails,hideDetails,paymentOption,setPinMessage
  } =
    useDeliveryDetails({
      refetch,
      providersState,
      // getFieldError,
      setStatus,
      status,
      hideStep
    });
  return (
    <div className="col">
      {!hideStep &&<div className={`pro-mb-4`}>
        <div className="row">
          {itemData?.type===1&&<div className="col-auto">
            <div className="pro-check-box radio">
              <input
                className="pro-check"
                type="radio"
                name="door_delivery"
                id="door_delivery"
                checked={formik?.values?.delivery_type===1}
                onChange={() => {formik.setFieldValue("delivery_type",1)
                setPinMessage("")
                formik.setFieldValue("pincode", "" );
                setHideDetails(false)}
              }
              />
              <label className="pro-check-label" htmlFor="door_delivery">
                Door Delivery
              </label>
            </div>
          </div>}
          <div className="col-auto">
            <div className="pro-check-box radio">
              <input
                className="pro-check"
                type="radio"
                name="pickup_from_branch"
                id="pickup_from_branch"
                checked={formik?.values?.delivery_type ===2}
                onChange={() => {formik.setFieldValue("delivery_type",2)
                  setHideDetails(true)
                  formik.setFieldValue("pincode", "" );
                  setPinMessage("")
                }}
              />
              <label className="pro-check-label" htmlFor="pickup_from_branch">
                Pickup From Branch
              </label>
            </div>
          </div>
        </div>
      </div>}
      {(!hideStep&& !hideDetails) && (
        <>
      <div className="input-wrap">
        <Input
          label={`Pincode ${handleCharge&&`(handle charge: ${handleCharge})`}`}
          type="text"
          id="pincode"
          name="pincode"
          max={6}
          value={formik.values.pincode}
          onBlur={formik.handleBlur}
          className={`pro-input lg ${(getFieldError("pincode")|| pinMessage) && " error"}`}
          // {...formik.getFieldProps("pincode")}
          error={getFieldError("pincode")|| pinMessage}
          errorMessage={getFieldError("pincode")?getFieldError("pincode"):pinMessage}
          onChange={(e)=>{handlePincodeChange(e)}}
          // onKeyDown={(e) => {
          //   const regex = /^[0-9]$/;
          //   const allowedKeys = [
          //     "Backspace",
          //     "ArrowLeft",
          //     "ArrowRight",
          //     "Tab",
          //     "Delete",
          //   ];
          //   if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
          //     e.preventDefault();
          //   }
          // }}
        />
      </div>
      <div className="input-wrap">
        <Input
          label={"Address"}
          type="text"
          id="address"
          name="address"
          className={`pro-input lg ${getFieldError("address") && " error"}`}
          {...formik.getFieldProps("address")}
          error={getFieldError("address")}
          errorMessage={getFieldError("address")}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="country" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Country
        </label>
        <Select
          id="country"
          placeholder={"Country"}
          className={`pro-input lg  ${formik.touched.country&&formik.errors.country&& !formik.values.country && " error"}`}
          classNamePrefix="pro-input"
          name="country"
          options={orderBasicData?.countries ?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={orderBasicData?.countries?.find(
            (value) => value?._id === formik?.values?.country
          )??""}
          onBlur={formik.handleBlur("country")}
          onChange={(value) => {
            formik.setFieldValue("country", value?._id );
            formik.setFieldValue("state", ""); // Clear state
            formik.setFieldValue("district", ""); // Clear district
          }}
          menuPlacement="auto"
          isClearable
        />

        {(formik.touched.country && formik.errors.country&& !formik.values.country) && (
          <span className="error-text">{formik.errors.country}</span>
        )}
      </div>
      <div className="pro-mb-4">
        <label
          htmlFor="state"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          State
        </label>
        <Select
          id="state"
          placeholder={"State"}
          className={`pro-input lg  ${(formik.touched.state && formik.errors.state&& !formik.values.state) && " error"}`}
          classNamePrefix="pro-input"
          name="state"
          options={
            orderBasicData?.countries?.find(
              (country) => country?._id === formik?.values?.country
            )?.states ?? []
          }
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={orderBasicData?.countries
            ?.find((country) => country?._id === formik?.values?.country)
            ?.states?.find((state) => state?._id === formik?.values?.state)??""}
          onBlur={formik.handleBlur("state")}
          onChange={(value) => {
            formik.setFieldValue("state", value?._id ?? "");
            formik.setFieldValue("district", ""); // Clear district
          }}
          menuPlacement="auto"
          isClearable
        />

        {(formik.touched.state && formik.errors.state&& !formik.values.state) && (
          <span className="error-text">{formik.errors.state}</span>
        )}
      </div>
      <div className="pro-mb-4">
        <label
          htmlFor="district"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          District
        </label>
        <Select
          id="district"
          placeholder={"District"}
          className={`pro-input lg  ${(formik.touched.district && formik.errors.district&& !formik.values.district) && " error"}`}
          classNamePrefix="pro-input"
          name="district"
          options={
            orderBasicData?.countries
              ?.find((country) => country?._id === formik?.values?.country)
              ?.states?.find((state) => state?._id === formik?.values?.state)
              ?.districts ?? []
          }
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={orderBasicData?.countries
            ?.find((country) => country?._id === formik?.values?.country)
            ?.states?.find((state) => state?._id === formik?.values?.state)
            ?.districts?.find(
              (district) => district?._id === formik?.values?.district
            )??""}
          onBlur={formik.handleBlur("district")}
          onChange={(value) => {
            formik.setFieldValue("district", value?._id ?? "");
          }}
          menuPlacement="top"
          isClearable
        />

        {(formik.touched.district && formik.errors.district&& !formik.values.district)  && (
          <span className="error-text">{formik.errors.district}</span>
        )}
      </div>
      </>
      )}
      
      <div className="pro-mb-4">
        <label htmlFor="country" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Select the payment mode
        </label>
        <Select
          id="payment_method"
          placeholder={"Select the payment mode"}
          className={`pro-input lg  ${getFieldError("payment_method") && " error"}`}
          classNamePrefix="pro-input"
          name="payment_method"
          options={paymentOption?? []}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={paymentOption?.find(
            (value) => value?.id === formik?.values?.payment_method
          )}
          onBlur={formik.handleBlur("payment_method")}
          onChange={(value) =>
            formik.setFieldValue("payment_method", value?.id ?? "")
          }
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("payment_method") && (
          <span className="error-text">{getFieldError("payment_method")}</span>
        )}
      </div>

      {/* <div className={`pro-mb-4`}>
        <div className="row">
          <div className="col-auto">
            <div className="pro-check-box radio">
              <input
                className="pro-check"
                type="radio"
                name="pay_to_branch"
                id="pay_to_branch"
                checked={formik?.values?.payment_method === 2}
                onChange={() => formik.setFieldValue("payment_method", 2)}
              />
              <label className="pro-check-label" htmlFor="pay_to_branch">
                Pay to Branch
              </label>
            </div>
          </div>
          <div className="col-auto">
            <div className="pro-check-box radio">
              <input
                className="pro-check"
                type="radio"
                name="pay_to_branch"
                id="pay_online"
                checked={formik?.values?.payment_method === 3}
                onChange={() => formik.setFieldValue("payment_method", 3)}
              />
              <label className="pro-check-label" htmlFor="pay_online">
                Pay Online
              </label>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={()=>handleClose()}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className={`pro-btn-primary lg pro-ms-3 pro-px-5 ${formik.isSubmitting ? "loading" : ""}`}
          disabled={formik.isSubmitting}
          onClick={() => {
            if(pinMessage===""){
              formik.handleSubmit();
            }
           }}
        >
         Next
        </Button>
      </div>
    </div>
  );
};

export default DeliveryDetails;
