import { useSelector, useDispatch } from "react-redux";
import {
  useGetPaymentListDataQuery,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/payment";
import { updateConfig } from "../../../store/slices/Payments/paymentsSlice";
import { addDays } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { getFormatedDate } from "../../../utils/functions/table";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useGetBasicDataQuery } from "../../../store/queries/global";
import { getPaymentDetailData } from "./PaymentDetailsPage/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const usePayments = ({ dashboard }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentState = useSelector((state) => state.payment);
  const { showEditModal } = useSelector((state) => state.global);

  const {
    data: paymentData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetPaymentListDataQuery({
    sort_by: paymentState.sortBy,
    sort_order: dashboard ? "desc" : paymentState.sortOrder,
    search: dashboard ? "" : paymentState.search,
    page_size: dashboard ? 10 : paymentState.currentPageSize,
    page: dashboard ? 1 : paymentState.currentPage,
    start: paymentState.startDate,
    end: paymentState.endDate,
    filter: dashboard ? "success" : paymentState?.currentFilter,
    branch_id: paymentState?.currentBranchFilter,
  });

  const { data: basicData = {} } = useGetBasicDataQuery();

  useEffect(() => {
    handleClearClick();
    //eslint-disable-next-line
  }, []);

  const [updateTableFields] = useUpdateTableFieldsDataMutation();

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handleSort = (label) => {
    if (paymentState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = paymentState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const hasTransactionViewPermission = useMemo(() => {
    let permission = paymentData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
    // eslint-disable-next-line
  }, [paymentData]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      amount: (field, data) => <p className="pro-mb-0"> ₹ {data[field]}</p>,
      transaction_id: (field, data) => {
        return hasTransactionViewPermission && !dashboard ? (
          <p onClick={() => handleView(data?.id)} className="pro-mb-0 pro-pnt">
            {data[field]}
          </p>
        ) : (
          <p className="pro-mb-0">{data[field]}</p>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
      })
    );
  };

  const [initialDateRange, setInitialDateRange] = useState({
    startDate: addDays(new Date(), -30),
    endDate: new Date(),
    key: "selection",
  });

  const handleDateChange = (range) => {
    setInitialDateRange(range);

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;

        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const filters = useMemo(() => {
    if (paymentData?.data?.filters) {
      const buttonGroups = paymentData?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, paymentData]);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
      localStorage.setItem("currentFilter", filter?.value);
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
      localStorage.setItem("currentFilter", filter.value);
    }
  };

  const handleBranchFilter = (ids) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentBranchFilter = ids;
      })
    );
  };
  const handleView = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showViewModal = true;
      })
    );

    getPaymentDetailData({ payment_id: id }).then((res) => {
      if (res?.status === 200) {
        dispatch(
          updateConfig((state) => {
            state.viewDetails = res?.data?.data;
          })
        );
      } else {
        toast.error("Failed to fetch payment details");
      }
    });
  };

  const closeViewModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showViewModal = false;
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/payment");
    dispatch(
      updateConfig((state) => {
        if (dashboard) {
          state.currentFilter = null;
          state.currentPage = 1;
        } else {
          state.currentPage = 1;
        }
      })
    );
    // }, 500);
  };

  return {
    paymentData,
    paymentState,
    paginationOptions,
    isLoading,
    isFetching,
    initialDateRange,
    actionOptions,
    showEditModal,
    filters,
    activeFilter: paymentState?.currentFilter,
    basicData,
    handleDashboardRedirect,
    closeViewModal,
    handleView,
    handleBranchFilter,
    handleFilter,
    updateTableFields,
    closeEditModal,
    refetch,
    handleActionChange,
    handleDateChange,
    handleClearClick,
    handleSort,
    handlePageSize,
    handlePagination,
    getRow,
    handleSearch,
  };
};

export default usePayments;
