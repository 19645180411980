import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateEmail } from "../api";

const useCaptureEmail = (emailRef) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");

  const [emailCaptureStatus, setEmailCaptureStatus] = useState("idle");
  const [otpInfo, setOtpInfo] = useState({});

  useEffect(() => {
    if (emailCaptureStatus === "success") {
      navigate(`/forgot-password/${"asd"}`);
    } else if (emailCaptureStatus === "failed") {
      setErrors(otpInfo?.otp);
    }
    // eslint-disable-next-line
  }, [emailCaptureStatus]);

  const handleBlur = () => {
    setErrors("");
  };

  const handleChange = (e) => {
    setErrors("");
    setEmail(e.target.value);
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (email) {
      setEmailCaptureStatus("pending");

      validateEmail({ email: emailRef.current, email: email }).then(
        (response) => {

          if (response?.data.success) {
            navigate(
              `/login/forgot-password/${response.data.data.email}`
            );

            setEmailCaptureStatus("success");
          } else {
            setErrors("Sorry Invalid Email");
            setEmailCaptureStatus("An error occured");
          }
        }
      );
    } else {
      setErrors("*Email cannot be empty");
    }
  };

  return {
    email,
    errors,
    emailCaptureStatus,
    handleChange,
    handleSubmit,
    handleBlur,
    handleLoginClick,
  };
};

export default useCaptureEmail;
