import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("branch_id")}${getParams(
          "branch_currency_id"
        )}${getParams("start_date")}${getParams("end_date")}${getParams(
          "filter"
        )}${getParams("sort_by")}is_active=${
          params?.is_active ? params["is_active"] : 0
        }&sort_order=${params?.sort_order || "desc"}&page_size=${
          params?.per_page || "10"
        }&${getParams("search")}page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const branches = createApi({
  reducerPath: "branchesApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "branches",
    "basic",
    "branch",
    "branch-currency",
    "branch-pincode",
    "branch-rate",
  ],
  endpoints: (builder) => ({
    getBranchListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/branch`,
      }),
      providesTags: ["branches", "branch-currency"],
    }),
    getBasicData: builder.query({
      query: () => ({
        method: "get",
        endpoint: `/admin/basic-data`,
      }),
      providesTags: ["basic"],
    }),

    getAllCurrencyData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/branch/currency/all`,
      }),
      providesTags: ["branch-currency"],
    }),
    getBranchCurrencyData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/branch/currency/view`,
      }),
      invalidatesTags: ["branch-currency"],
    }),
    getBranchPincodeData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/branch/pincode`,
      }),
      providesTags: ["branch-pincode"],
    }),
    getBranchRateData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/branch/live-rate`,
      }),
      providesTags: ["branch-rate"],
    }),
    updateTableFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/user/fields/update`,
      }),
      invalidatesTags: ["branches"],
    }),
    updateBranchStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/branch/change/status`,
      }),
    }),
    getBranchData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/branch/view`,
      }),
      providesTags: ["branch"],
    }),

    updateBranchPincodeStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/branch/pincode/change/status`,
      }),
      invalidatesTags: ["currency"],
    }),
  }),
});

export const {
  useGetBranchListDataQuery,
  useGetBasicDataQuery,
  useUpdateTableFieldsDataMutation,
  useUpdateBranchStatusMutation,
  useGetBranchDataQuery,
  useGetBranchCurrencyDataQuery,
  useGetAllCurrencyDataQuery,
  useGetBranchPincodeDataQuery,
  useUpdateBranchPincodeStatusMutation,
  useGetBranchRateDataQuery,
} = branches;
