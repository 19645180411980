import DashboardHero from "../DashboardHero";
import useDashboardListing from "./useDashboardListing";
import Style from "./dashboardListing.module.scss";
import EmptyData from "../../Global/EmptyData";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";

const DashboardListing = () => {
  const {
    dashboardItems,
    dashboardData,
    isFetching,
    children,
    listingType,
    date,
    totalValue,
    handleDateRangeChange,
  } = useDashboardListing();

  return (
    <div className={Style.dashboard_wrap}>
      <div className=" pro-d-flex pro-justify-end ">
        <CustomDateRangePicker
          defaultDateRange={date}
          handleDateRangeChange={handleDateRangeChange}
          placement={"bottomRight"}
        />
      </div>
      <div className={`pro-w-100 pro-my-5`}>
        <DashboardHero
          data={dashboardData?.data?.cards?.[0]}
          obj={dashboardItems}
          loading={isFetching}
          listingType={listingType}
          shimmerCount={6}
          totalValue={totalValue}
        />
      </div>
      {dashboardData?.data && Object.keys(dashboardData?.data)?.length !== 0 ? (
        children
      ) : (
        <>
          <EmptyData />
        </>
      )}
    </div>
  );
};

export default DashboardListing;
