
import { useEffect, useState } from 'react';

const useOrderDetails = ({ formik,basicData,currencyData}) => {
    const [index, setIndex] = useState();


    useEffect(() => {
      if (formik?.values?.order_details) {
        const totalSum = formik?.values?.order_details?.reduce((acc, item) => {
          return acc + (parseFloat(item?.unit_amount) || 0); 
        }, 0);
        let finalTotal = Number(totalSum?.toFixed(2));
        formik?.setFieldValue(`total_amount`, finalTotal);
      }
      // eslint-disable-next-line
    }, [formik?.values?.order_details]);
    
    const handleRemoveField = (index) => {
      let lastAmount = formik?.values?.total_amount;
      lastAmount = formik?.values?.total_amount - formik?.values?.order_details?.[index]?.unit_amount;
      formik.setFieldValue(`total_amount`, lastAmount??"")
      const data = formik?.values?.order_details?.filter(
        (_, itemIndex) => itemIndex !== index
      );
      formik.setFieldValue("order_details", data);
    };
    const handleOptions = () => {
      const currencyIds = formik?.values?.order_details
        ?.filter(serviceType => serviceType?.currency_code) 
        ?.map(serviceType => serviceType.currency_code.id) || []; 
      const filteredData = currencyData?.currency_list
        ?.filter(item => !currencyIds.includes(item.id)) || []; 
      const resultData = filteredData.length > 0 ? filteredData : [];
      return resultData
      // }
    };
    return {
      handleRemoveField,
      setIndex,
      index,
      handleOptions
    };
  };
  
  export default useOrderDetails;
  