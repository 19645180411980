import { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/GeneralEnquiry/generalEnquirySlice";
import { useGetGeneralEnquiryDataQuery } from "../../../store/queries/generalEnquiry";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import Select from "react-select";
import { toast } from "react-toastify";
import { updateGeneralEnquiry } from "./api";

const useGeneralEnquiry = () => {
  const dispatch = useDispatch();
  const [statusModal, setStatusModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [statusReason, setStatusReason] = useState("");
  const [errors, setErrors] = useState("");

  const generalEnquiryState = useSelector((state) => state.generalEnquiry);

  const {
    data: generalEnquiryData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetGeneralEnquiryDataQuery({
    sort_by: generalEnquiryState.sortBy,
    sort_order: generalEnquiryState.sortOrder,
    search: generalEnquiryState.search,
    page_size: generalEnquiryState.currentPageSize,
    page: generalEnquiryState.currentPage,
    filter: generalEnquiryState.currentFilter,
  });

  const filterOptions = [
    {
      name: "All",
      value: 3,
    },
    {
      name: "Pending",
      value: 1,
    },
    {
      name: "Completed",
      value: 2,
    },
  ];

  const statusOptions = [
    {
      name: "Pending",
      value: 1,
    },
    {
      name: "Completed",
      value: 2,
    },
  ];

  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const filters = useMemo(() => {
    if (filterOptions) {
      const buttonGroups = filterOptions?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, []);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
      localStorage.setItem("currentFilter", filter?.value);
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
      localStorage.setItem("currentFilter", filter.value);
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
      })
    );
  };

  const hasChangeStatusPermission = useMemo(() => {
    let permission = generalEnquiryData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );
    return permission?.[0]?.can_change_status ?? 0;
    // eslint-disable-next-line
  }, [generalEnquiryData]);

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (field, data) => {
        let selectVal = statusOptions.find((item) => item.name === data[field]);

        return hasChangeStatusPermission ? (
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input lg`}
            classNamePrefix="pro-input"
            options={statusOptions}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.value}
            value={selectVal}
            onChange={(value) => {
              if (value?.name === "Completed") {
                setStatusModal(true);
                setSelectedItem(data);
              }
            }}
            menuPlacement="auto"
            menuPosition="fixed"
            isDisabled={selectVal?.value === 2}
          />
        ) : (
          <p>{data[field]}</p>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSort = (label) => {
    if (generalEnquiryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder =
            generalEnquiryState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const closeStatusReasonModal = () => {
    setStatusModal(false);
  };

  const handleSubmitStatusChange = () => {
    if (statusReason?.length <= 0) {
      setErrors("completion comment  is required");
    } else {
      setErrors("");
      const obj = {
        general_enquiry_id: selectedItem?.id,
        review_comment: statusReason,
      };
      updateGeneralEnquiry(obj).then((res) => {
        if (res?.status === 200) {
          toast.success("Status changed successfully");
          refetch();
          setStatusModal(false);
          setErrors("");
          setStatusReason("");
          setSelectedItem("");
        } else {
          toast.error("Failed to change status");
          setStatusModal(false);
          setErrors("");
          setStatusReason("");
          setSelectedItem("");
        }
      });
    }
  };

  return {
    filters,
    activeFilter: generalEnquiryState?.currentFilter,
    isLoading,
    actionOptions,
    generalEnquiryState,
    generalEnquiryData,
    isFetching,
    paginationOptions,
    statusModal,
    statusReason,
    errors,
    handleSubmitStatusChange,
    setStatusReason,
    closeStatusReasonModal,
    handlePageSize,
    handlePagination,
    handleSort,
    getRow,
    handleClearClick,
    handleActionChange,
    handleFilter,
    handleSearch,
  };
};

export default useGeneralEnquiry;
