import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { updateConfig } from "../../../../Store/Slices/Expertise/expertiseSlice";
// import { createExpertise, updateExpertise } from "../api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  getViewData,
  updateConfig,
} from "../../../../../store/slices/OrderCreate/orderCreateSlice";
import { documentRemove, documentSubmit, documentUpload } from "./api";

const useDocuments = ({ refetch }) => {
  const dispatch = useDispatch();
  const { isEdit, itemData, orderBasicData, orderId} = useSelector(
    (state) => state.orderCreate
  );
  const orderCreateState = useSelector((state) => state.orderCreate);
  const { basicData } = useSelector((state) => state.global);

  const validationSchema = Yup.object({
    passport_front_id: Yup.string().required("*Passport Front is required"),
    passport_back_id: Yup.string().required("*Passport Back is required"),
    ticket: Yup.string().required("*Ticket is required"),
    visa: Yup.string(),
    other_document: Yup.string(),
    pan_card_id: Yup.string().required("*Pan Card is required"),
    pan_number: Yup.string()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Invalid PAN format.")
      .required("*Pan Card Number is required"),
  });
  const formik = useFormik({
    initialValues: {
      passport_front_id: itemData?.order_documents?.documents?.find((item)=>(item?.id_type===1))?.id ?? "",
      passport_back_id: itemData?.order_documents?.documents?.find((item)=>(item?.id_type===2))?.id  ?? "",
      ticket: itemData?.order_documents?.documents?.find((item)=>(item?.id_type===3))?.id?? "",
      visa: itemData?.order_documents?.documents?.find((item)=>(item?.id_type===4))?.id ?? "",
      other_document:itemData?.order_documents?.documents?.find((item)=>(item?.id_type===5))?.id ?? "",
      pan_card_id: itemData?.order_documents?.documents?.find((item)=>(item?.id_type===6))?.id  ?? "",
      pan_number: itemData?.pan_number ?? "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
        let data = {
          order_id: orderId,
          passport_front_id: values.passport_front_id,
          passport_back_id: values?.passport_back_id,
          ticket: values?.ticket,
          pan_card_id: values?.pan_card_id,
          pan_number: values?.pan_number,
          previous_amount_status: 0,
          previous_amount: 0,
        };
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        formData.append("_method", "PUT");
        documentSubmit(formData).then((response) => {
          if (response?.status === 200) {
            toast.success("Documents have been added.");
            dispatch(
              updateConfig((state) => {
                state.formActiveTab = "Delivery Details";
              })
            );
            dispatch(
              updateConfig((state) => {
                state.formActiveTab ="Delivery Details"
                state.orderId=response?.data?.data?._id
                state.EditCreate.productDetails= orderCreateState?.EditCreate.productDetails;
                state.EditCreate.travelDetails= orderCreateState?.EditCreate.travelDetails;
                state.EditCreate.deliveryDetails = orderCreateState?.EditCreate.documentDetails;
                state.EditCreate.documentDetails = true;
              })
            );
            setSubmitting(false);
          } else if (response?.status === 422) {
            let errors = response?.errors;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else if (response?.success === false) {
            let errors = response?.message;
            let errorFields = Object.keys(errors);
            errorFields?.forEach((field) => {
              formik.setFieldError(field, errors[field]);
            });
            setSubmitting(false);
          } else {
            toast.error("Something went wrong");
            setSubmitting(false);
          }
        });
      }
  });
  const getFieldError = (fieldName) => {
    if (formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleImageChange = (event, id) => {
    const selectedImage = event?.target?.files[0];
    let data = {
      document: selectedImage,
      document_id_type: id,
      order_id: orderId,
    };
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    formData.append("_method", "PUT");
    documentUpload(formData).then((response) => {
      if (response?.status === 200) {
        toast.success("Document has been added.");
        // if (id === 1) {
        //   formik.setFieldValue("passport_front_id", response?.data?.data);
        // } else if (id === 2) {
        //   formik.setFieldValue("passport_back_id", response?.data?.data);
        // } else if (id === 3) {
        //   formik.setFieldValue("ticket", response?.data?.data);
        // } else if (id === 4) {
        //   formik.setFieldValue("visa", response?.data?.data);
        // } else if (id === 5) {
        //   formik.setFieldValue("other_document", response?.data?.data);
        // } else if (id === 6) {
        //   formik.setFieldValue("pan_card_id", response?.data?.data);
        // }
        dispatch(getViewData({ id: orderId }));
      } else if (response?.status === 422) {
        let errors = response?.errors;
        let errorFields = Object.keys(errors);
        errorFields?.forEach((field) => {
          if (id === 1) {
            formik.setFieldError("passport_front_id", errors[field]);
          } else if (id === 2) {
            formik.setFieldError("passport_back_id", errors[field]);
          } else if (id === 3) {
            formik.setFieldError("ticket", errors[field]);
          } else if (id === 4) {
            formik.setFieldError("visa", errors[field]);
          } else if (id === 5) {
            formik.setFieldError("other_document", errors[field]);
          } else if (id === 6) {
            formik.setFieldError("pan_card_id", errors[field]);
          }
          // formik.setFieldError(field, errors[field]);
        });
      } else if (response?.success === false) {
        let errors = response?.data;
        let errorFields = Object.keys(errors);
        errorFields?.forEach((field) => {
          if (id === 1) {
            formik.setFieldError("passport_front_id", errors[field]?.[0]);
          } else if (id === 2) {
            formik.setFieldError("passport_back_id", errors[field]?.[0]);
          } else if (id === 3) {
            formik.setFieldError("ticket", errors[field]?.[0]);
          } else if (id === 4) {
            formik.setFieldError("visa", errors[field]?.[0]);
          } else if (id === 5) {
            formik.setFieldError("other_document", errors[field]?.[0]);
          } else if (id === 6) {
            formik.setFieldError("pan_card_id", errors[field]?.[0]);
          }
          // formik.setFieldError(field, errors[field]);
        });
      } else {
        toast.error("Something went wrong");
      }
    });

  };
  const handleImageRemove = (document_id, id) => {
    documentRemove(document_id).then((response) => {
      if (response?.status === 200) {
        toast.success("Document has been deleted");
        dispatch(getViewData({ id: orderId }));
        if (id === 1) {
          formik.setFieldValue("passport_front_id", "");
        } else if (id === 2) {
          formik.setFieldValue("passport_back_id", "");
        } else if (id === 3) {
          formik.setFieldValue("ticket", "");
        } else if (id === 4) {
          formik.setFieldValue("visa", "");
        } else if (id === 5) {
          formik.setFieldValue("other_document", "");
        } else if (id === 6) {
          formik.setFieldValue("pan_card_id", "");
        }
      } else if (response?.status === 422) {
        let errors = response?.errors;
        let errorFields = Object.keys(errors);
        errorFields?.forEach((field) => {
          formik.setFieldError(field, errors[field]);
        });
      } else if (response?.success === false) {
        let errors = response?.message;
        let errorFields = Object.keys(errors);
        errorFields?.forEach((field) => {
          formik.setFieldError(field, errors[field]);
        });
      } else {
        toast.error("Something went wrong");
      }
    });
  };
  const handleNext = () => {
    formik.handleSubmit();
  };
  (function () {
    let fields = [
      "passport_front_id",
      "passport_back_id",
      "ticket",
      // "visa",
      // "other_document",
      "pan_card_id",
      "pan_number",
    ];
    // eslint-disable-next-line array-callback-return
    var completedFields = fields?.filter((field) => {
      if (Array.isArray(formik?.values[field])) {
        return formik?.values[field]?.length > 0;
      }else {
        return !!formik?.values[field];
      }
    });
    dispatch(
      updateConfig((state) => {
        state.documentDetails_percentage = (completedFields.length / fields.length) * 100
      })
    );
    // return 
  })()
  return {
    formik,
    isEdit,
    orderBasicData,
    getFieldError,
    handleImageChange,
    handleImageRemove,
    handleNext,
    basicData,
    itemData
  };
};

export default useDocuments;
