import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import Index from "../pages";
import AuthModule from "../components/Auth";
import ForgotPassword from "../components/Auth/ForgotPassword";
import Login from "../components/Auth/Login";
import ResetPassword from "../components/Auth/ResetPassword";
import Roles from "../components/Configure/roles";

import CaptureEmail from "../components/Auth/CaptureEmailForm";
import Dashboard from "../components/Dashboard";
import DashboardListing from "../components/Dashboard/DashboardListing";
import Notification from "../components/Notification";
import NotificationListing from "../components/Notification/NotificationListing";
import ManageRoles from "../components/Configure/roles/ManageRoles";
import Product from "../components/Configure/product";
import Purpose from "../components/Configure/travelPurpose";
import Delivery from "../components/Configure/deliveryType";
import Staffs from "../components/Configure/staffs";
import Branches from "../components/Branch/branches";
import Currency from "../components/Configure/currency";
import UserAdmin from "../components/Users/user";
import BranchDetailLayout from "../components/Branch/branches/branchDetailLayout";
import BranchPincode from "../components/Branch/branches/BranchPincode";
import BranchRate from "../components/Branch/branches/BranchRate";
import BranchCurrency from "../components/Branch/branches/BranchCurrency";

import OrderDetailLayout from "../components/Orders/orderListing/OrderDetailLayout";
import OrderDetails from "../components/Orders/orderListing/OrderDetails";
import TravelDetails from "../components/Orders/orderListing/TravelDetails";
import DeliveryDetails from "../components/Orders/orderListing/DeliveryDetails";
import DocumentDetails from "../components/Orders/orderListing/DocumentDetails";
import Order from "../components/Orders";
import Users from "../components/Users";
import Orders from "../components/Orders/orderListing";
import Branch from "../components/Branch";
import Configure from "../components/Configure";
import Callback from "../components/Configure/Callback";
import Payment from "../components/Payment";
import Payments from "../components/Payment/payments";
import BankDetails from "../components/Configure/bankDetails";
import ContactUs from "../components/Configure/ContactUs";
import PaymentDetailsOrder from "../components/Orders/orderListing/PaymentDetailsOrder";
import GeneralEnquiry from "../components/Configure/generalEnquiry";
import ActivityLog from "../components/Configure/activityLog";
import UserDetailLayout from "../components/Users/UserDetailLayout";
import UserDetails from "../components/Users/UserDetails";
import ActivityLogOrder from "../components/Orders/orderListing/ActivityLogOrder";

const Routes = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<Index />} />
        <Route path="dashboard" element={<Dashboard />}>
          <Route path="" element={<DashboardListing />} />
        </Route>
        <Route path="notifications" element={<Notification />}>
          <Route path="" element={<NotificationListing />} />
        </Route>
        <Route path="orders" element={<Order />}>
          <Route path="" element={<Orders />} />
        </Route>
        <Route path="user" element={<Users />}>
          <Route path="" element={<UserAdmin />} />
        </Route>
        <Route path="/user/user-details" element={<UserDetailLayout />}>
          <Route path=":userID" element={<UserDetails />}></Route>
        </Route>
        <Route path="branches" element={<Branch />}>
          <Route path="" element={<Branches />} />
        </Route>
        <Route path="payment" element={<Payment />}>
          <Route path="" element={<Payments />} />
        </Route>

        <Route path="configure" element={<Configure />}>
          <Route path="role" element={<Roles />}></Route>
          <Route path="role/permission" element={<ManageRoles />}></Route>
          <Route path="product" element={<Product />}></Route>
          <Route path="staffs" element={<Staffs />}></Route>
          <Route path="travel-purpose" element={<Purpose />}></Route>
          <Route path="delivery-type" element={<Delivery />}></Route>
          <Route path="currency" element={<Currency />}></Route>
          <Route path="callback" element={<Callback />} />
          <Route path="general-enquiry" element={<GeneralEnquiry />} />
          <Route path="bank-details" element={<BankDetails />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="activity-log" element={<ActivityLog />} />
        </Route>
        <Route path="branches/branch-detail" element={<BranchDetailLayout />}>
          <Route path=":branch_id" element={<BranchCurrency />}></Route>
          <Route
            path="pincode-detail/:branch_id"
            element={<BranchPincode />}
          ></Route>
          <Route path="rate/:branch_id" element={<BranchRate />}></Route>
        </Route>

        <Route path="orders/order-details" element={<OrderDetailLayout />}>
          {/* <Route path="" element={<OrderDetails />}></Route> */}
          {/* <Route path=":orderID" element={<OrderDetails />}></Route> */}
          <Route path=":orderID/:ID" element={<OrderDetails />}></Route>
          <Route
            path="travel-details/:orderID/:ID"
            element={<TravelDetails />}
          ></Route>
          <Route
            path="delivery-details/:orderID/:ID"
            element={<DeliveryDetails />}
          ></Route>
          <Route
            path="document-details/:orderID/:ID"
            element={<DocumentDetails />}
          ></Route>
          <Route
            path="order-payments-details/:orderID/:ID"
            element={<PaymentDetailsOrder />}
          ></Route>
          <Route
            path="activity-log/:orderID/:ID"
            element={<ActivityLogOrder />}
          ></Route>
        </Route>

        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />}></Route>
          <Route
            path="forgot-password/capture-email"
            element={<CaptureEmail />}
          ></Route>
          <Route
            path="forgot-password/:email"
            element={<ForgotPassword />}
          ></Route>
          <Route path="reset-password" element={<ResetPassword />}></Route>
        </Route>

        <Route path={"/*"} element={<Navigate to={"/"} />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
