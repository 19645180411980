import { combineReducers } from "@reduxjs/toolkit";
import { settings } from "./queries/settings";
import { global } from "./queries/global";
import globalSlice from "./slices/Global";
import { dashboard } from "./queries/Dashboard";
import dashboardSlice from "./slices/Dashboard/dashboardSlice";
import { product } from "./queries/product";
import { profile } from "./queries/profile";
import profileSlice from "./slices/Profile/profileSlice";
import { notificationRtk } from "./queries/notifications";
import notificationSlice from "./slices/Notifications/notificationSlice";
import { roles } from "./queries/roles";
import rolesSlice from "./slices/Roles/rolesSlice";
import settingSlice from "./slices/Settings/settingSlice";
import productSlice from "./slices/Product/productSlice";
import { purpose } from "./queries/purpose";
import purposeSlice from "./slices/Purpose/purposeSlice";
import { delivery } from "./queries/delivery";
import deliverySlice from "./slices/Delivery/deliverySlice";
import { staffs } from "./queries/staffs";
import staffsSlice from "./slices/Staffs/staffsSlice";
import { branches } from "./queries/branches";
import branchSlice from "./slices/Branches/branchSlice";
import branchRateFormSlice from "./slices/Branches/branchRateFormSlice";
import branchPincodeSlice from "./slices/Branches/branchPincodeSlice";
import { user } from "./queries/user";
import userSlice from "./slices/user/userSlice";
import { currency } from "./queries/currency";
import currencySlice from "./slices/currency/currencySlice";
import { orders } from "./queries/orders";
import orderSlice from "./slices/orders/orderSlice";
import callbackSlice from "./slices/Callback/callbackSlice";
import { callback } from "./queries/callback";
import paymentsSlice from "./slices/Payments/paymentsSlice";
import { payment } from "./queries/payment";
import bankDetailsSlice from "./slices/BankDetails/bankDetailsSlice";
import { bankDetails } from "./queries/bankDetails";
import contactUsSlice from "./slices/ContactUsSlice/contactUsSlice";
import { contactUs } from "./queries/contactUs";
import { generalEnquiry } from "./queries/generalEnquiry";
import generalEnquirySlice from "./slices/GeneralEnquiry/generalEnquirySlice";
import { ordersCreate } from "./queries/OrderCreate";
import orderCreateSlice from "./slices/OrderCreate/orderCreateSlice";
import { activityLog } from "./queries/activityLog";
import activityLogSlice from "./slices/ActivityLog/activityLogSlice"
import userDetailSlice from "./slices/user/userDetailSlice"

const appReducer = combineReducers({
  [product.reducerPath]: product.reducer,
  [roles.reducerPath]: roles.reducer,
  [settings.reducerPath]: settings.reducer,
  [dashboard.reducerPath]: dashboard.reducer,
  [profile.reducerPath]: profile.reducer,
  [notificationRtk.reducerPath]: notificationRtk.reducer,
  [purpose.reducerPath]: purpose.reducer,
  [delivery.reducerPath]: delivery.reducer,
  [staffs.reducerPath]: staffs.reducer,
  [currency.reducerPath]: currency.reducer,
  [branches.reducerPath]: branches.reducer,
  [user.reducerPath]: user.reducer,
  [global.reducerPath]: global.reducer,
  [orders.reducerPath]: orders.reducer,
  [callback.reducerPath]: callback.reducer,
  [payment.reducerPath]: payment.reducer,
  [bankDetails.reducerPath]: bankDetails.reducer,
  [contactUs.reducerPath]: contactUs.reducer,
  [generalEnquiry.reducerPath]: generalEnquiry.reducer,
  [ordersCreate.reducerPath]:ordersCreate.reducer,
  [activityLog.reducerPath]: activityLog.reducer,
  activityLog: activityLogSlice,
  staffs: staffsSlice,
  delivery: deliverySlice,
  purpose: purposeSlice,
  profile: profileSlice,
  product: productSlice,
  roles: rolesSlice,
  global: globalSlice,
  notification: notificationSlice,
  settings: settingSlice,
  currency: currencySlice,
  branch: branchSlice,
  user: userSlice,
  orders: orderSlice,
  branchRateForm: branchRateFormSlice,
  branchPincode: branchPincodeSlice,
  callback: callbackSlice,
  payment: paymentsSlice,
  bankDetails: bankDetailsSlice,
  contactUs: contactUsSlice,
  dashboard: dashboardSlice,
  generalEnquiry: generalEnquirySlice,
  orderCreate:orderCreateSlice,
  userDetail:userDetailSlice,
});

export default appReducer;
