import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { useSelector } from "react-redux";
import Style from "./orderSummary.module.scss"
const OrderSummary = ({handleClose}) => {
  const {itemData} = useSelector((state) => state.orderCreate);
  return (
    <div className={`pro-w-100 ${Style.root}`}>
      <div className="pro-mb-5">
        <h6 className="pro-ttl pro-mb-3">Order Summary</h6>
        <div className={`pro-d-flex ${Style.item}`}>
          <p>Order Number</p>
          <p>{itemData?.order_number}</p>
        </div>
        <div className={`pro-d-flex ${Style.item}`}>
          <p>Forex INR</p>
          <p>{itemData?.forex_amount}</p>
        </div>
        <div className={`pro-d-flex ${Style.item}`}>
          <p>Handling Charge</p>
          <p>{itemData?.handling_charge??0}</p>
        </div>
        <div className={`pro-d-flex ${Style.item}`}>
          <p>TCS Amount</p>
          <p>{itemData?.tcs??0}</p>
        </div>
        {itemData?.gst?.CGST&& 
        <div className={`pro-d-flex ${Style.item}`}>
          <p>CGST</p>
          <p>{itemData?.type===2&&"-"}{itemData?.gst?.CGST}</p>
        </div>}
       {itemData?.gst?.SGST&& <div className={`pro-d-flex ${Style.item}`}>
          <p>SGST</p>
          <p>{itemData?.type===2&&"-"}{itemData?.gst?.SGST}</p>
        </div>}
        {itemData?.gst?.IGST&& <div className={`pro-d-flex ${Style.item}`}>
          <p>IGST</p>
          <p>{itemData?.type===2&&"-"}{itemData?.gst?.IGST}</p>
        </div>}
        <div className={`pro-d-flex ${Style.item}`}>
          <p>Round Off</p>
          <p>{itemData?.round_off?.type}{itemData?.round_off?.amount}</p>
        </div>
        <div className={`pro-d-flex pro-mt-5 ${Style.item}`}>
          <h6 className="pro-fw-bolder">Total</h6>
          <h6 className="pro-fw-bolder">{itemData?.total_amount}</h6>
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          type="submit"
          className={"pro-btn-primary lg pro-ms-3 pro-px-5"}
          onClick={() => {
            handleClose();
          }}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default OrderSummary;
