import React from "react";
import ReactDOM from "react-dom/client";

import { configureStore } from "@reduxjs/toolkit";
import App from "./components/App";
import { Provider } from "react-redux";
import appReducer from "./store";
import { settings } from "./store/queries/settings";
import { dashboard } from "./store/queries/Dashboard";
import { product } from "./store/queries/product";
import { profile } from "./store/queries/profile";
import { notificationRtk } from "./store/queries/notifications";
import { roles } from "./store/queries/roles";
import { purpose } from "./store/queries/purpose";
import { delivery } from "./store/queries/delivery";
import { staffs } from "./store/queries/staffs";
import { currency } from "./store/queries/currency";
import { branches } from "./store/queries/branches";
import { user } from "./store/queries/user";
import { global } from "./store/queries/global";
import { orders } from "./store/queries/orders";
import { callback } from "./store/queries/callback";
import { payment } from "./store/queries/payment";
import { bankDetails } from "./store/queries/bankDetails";
import { contactUs } from "./store/queries/contactUs";
import { generalEnquiry } from "./store/queries/generalEnquiry";
import { ordersCreate } from "./store/queries/OrderCreate";
import { activityLog } from "./store/queries/activityLog";

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      settings.middleware,
      product.middleware,
      roles.middleware,
      dashboard.middleware,
      profile.middleware,
      notificationRtk.middleware,
      purpose.middleware,
      delivery.middleware,
      staffs.middleware,
      activityLog.middleware,
      currency.middleware,
      branches.middleware,
      user.middleware,
      global.middleware,
      orders.middleware,
      callback.middleware,
      payment.middleware,
      bankDetails.middleware,
      contactUs.middleware,
      generalEnquiry.middleware,
      ordersCreate.middleware
    ),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
