import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useBankDetailsForm from "./useBankDetailsForm";
import { capitalizeOnSpace } from "../../../../utils/functions/table";

const BankDetailsForm = ({ refetch, closeModal, isStickyFooter }) => {
  const { formik, is_edit } = useBankDetailsForm({ refetch, closeModal });
  return (
    <>
      <div className={`pro-w-100`}>
        <Input
          label={"Account Holder Name*"}
          type="text"
          id="account_holder_name"
          name="account_holder_name"
          className={`pro-input lg ${
            formik.errors.account_holder_name &&
            formik.touched.account_holder_name &&
            "error"
          }`}
          {...formik.getFieldProps("account_holder_name")}
          onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={
            formik.errors.account_holder_name &&
            formik.touched.account_holder_name
          }
          errorMessage={formik.errors.account_holder_name}
        />

        <Input
          label={"Account Number*"}
          type="text"
          id="confirm_account_number"
          name="account_number"
          className={`pro-input lg ${
            formik.errors.account_number &&
            formik.touched.account_number &&
            "error"
          }`}
          {...formik.getFieldProps("account_number")}
          onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={formik.errors.account_number && formik.touched.account_number}
          errorMessage={formik.errors.account_number}
        />

        <Input
          label={"Confirm Account Number*"}
          type="text"
          id="confirm_account_number"
          name="confirm_account_number"
          className={`pro-input lg ${
            formik.errors.confirm_account_number &&
            formik.touched.confirm_account_number &&
            "error"
          }`}
          {...formik.getFieldProps("confirm_account_number")}
          onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={
            formik.errors.confirm_account_number &&
            formik.touched.confirm_account_number
          }
          errorMessage={formik.errors.confirm_account_number}
        />

        <Input
          label={"IFSC code*"}
          type="text"
          id="ifsc_code"
          name="ifsc_code"
          className={`pro-input lg ${
            formik.errors.ifsc_code && formik.touched.ifsc_code && "error"
          }`}
          {...formik.getFieldProps("ifsc_code")}
          onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={formik.errors.ifsc_code && formik.touched.ifsc_code}
          errorMessage={formik.errors.ifsc_code}
        />

        <Input
          label={"Branch*"}
          type="text"
          id="branch"
          name="branch"
          className={`pro-input lg ${
            formik.errors.branch && formik.touched.branch && "error"
          }`}
          {...formik.getFieldProps("branch")}
          onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={formik.errors.branch && formik.touched.branch}
          errorMessage={formik.errors.branch}
        />

        <Input
          label={"Bank Name*"}
          type="text"
          id="bank_name"
          name="bank_name"
          className={`pro-input lg ${
            formik.errors.bank_name && formik.touched.bank_name && "error"
          }`}
          {...formik.getFieldProps("bank_name")}
          onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={formik.errors.bank_name && formik.touched.bank_name}
          errorMessage={formik.errors.bank_name}
        />
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {is_edit ? "Update" : "Create"}
        </Button>
      </div>
    </>
  );
};
export default BankDetailsForm;
