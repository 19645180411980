import {
    Pagination,
    SearchFilters,
    Table,
  } from "@wac-ui-dashboard/wac_component_library";
  import { FaSort } from "react-icons/fa";
  import EmptyData from "../../../Global/EmptyData";
import useActivityLogOrder from "./useActivityLogOrder";
  
  const ActivityLogOrder = () => {
    const {
      mainData,
      menuState,
      currentPage,
      paginationOptions,
      isFetching,
      isLoading,
      handlePagination,
      handlePageSize,
      handleSort,
      handleSearch,
      getRow,
      handleClearClick,
    } = useActivityLogOrder();
    const pageSize =paginationOptions?.filter(
      (item) =>  menuState?.activity_log_Page?.currentPageSize === item?.value
    )?.[0]
    return (
      <>
         <div className={`col-auto pro-pt-5 pro-pb-6`}>
         <h6 className="pro-ttl h6 pro-mb-3">Activity Log</h6>
          <div className="row">
            <div className="col">
              <SearchFilters
                data={mainData?.data?.filters}
                onSearchInput={handleSearch}
                showActions={false}
                loading={isLoading}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                showClearFilters
                handleClear={handleClearClick}
                searchInputProps={{ value: menuState?.activity_log_Page?.search }}
              />
            </div>
          </div>
  
          {mainData?.data?.data?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              data={mainData?.data?.data || []}
              uniqueID={"_id"}
              showCheckBox={false}
              deletable={false}
              editable={false}
              clear={menuState?.activity_log_Page?.clearSelection}
              multiSelect={false}
              assignable={false}
              fields={mainData?.data?.fields}
              SortIcon={<FaSort />}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={menuState?.activity_log_Page?.currentPageSize}
              // handleSelect={checkIsActive}
            />
          
          )}
          {mainData?.data?.data?.length > 0 && (
            <Pagination
              currentPage={currentPage}
              defaultValue={pageSize}
              totalPageCount={Math.ceil(
                mainData?.data?.total_count / menuState?.activity_log_Page?.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}
        </div>
      </>
    );
  };
  
  export default ActivityLogOrder;
  