import { useSelector } from "react-redux";

const usePaymentDetails = () => {
  const { viewDetails } = useSelector((state) => state.payment);

  const label = {
    pg_type: "PG Type ",
    addedon: "Added On ",
    amount: "Amount ",
    auth_code: "Auth Code ",
    bank_name: "Bank Name ",
    bank_ref_num: "Bank Reference Number ",
    bankcode: "Bank Code ",
    card_type: "Card Type ",
    cardnum: "Card Number ",
    cash_back_percentage: "Cashback Percentage ",
    deduction_percentage: "Deduction Percentage ",
    easepayid: "Easepay ID ",
    email: "Email ",
    error: "Error ",
    error_message: "Error Message ",
    first_name: "First Name ",
    furl: "Fail URL ", //aswathy verified
    surl: "Success URL ",
    hash: "Hash ",
    issuing_bank: "Issuing Bank ",
    key: "Key ",
    merchant_logo: "Merchant Logo ",
    mode: "Mode ",
    name_on_card: "Name On Card ",
    net_amount_debit: "Net Amount Debit ",
    payment_source: "Payment Source ",
    phone: "Phone ",
    productinfo: "Product Info ",
    status: "Status ",
    transaction_id: "Transaction ID ",
    udf1: "Unique Identifier 1 ", //aswathy verified
    udf2: "Unique Identifier 2 ", //aswathy verified
    udf3: "Unique Identifier 3 ", //aswathy verified
    udf4: "Unique Identifier 4 ", //aswathy verified
    udf5: "Unique Identifier 5 ", //aswathy verified
    udf6: "Unique Identifier 6 ", //aswathy verified
    udf7: "Unique Identifier 7 ", //aswathy verified
    udf8: "Unique Identifier 8 ", //aswathy verified
    udf9: "Unique Identifier 9 ", //aswathy verified
    udf10: "Unique Identifier 10 ", //aswathy verified
    unmappedstatus: "Unmapped Status ",
    upi_va: "Upi va ",
  };

  const value = {
    pg_type: `${viewDetails?.pg_type ?? "Not Specified"}`,
    addedon: `${viewDetails?.addedon ?? "Not Specified"}`,
    amount: `${viewDetails?.amount ?? "Not Specified"}`,
    auth_code: `${viewDetails?.auth_code ?? "Not Specified"}`,
    bank_name: `${viewDetails?.bank_name ?? "Not Specified"}`,
    bank_ref_num: `${viewDetails?.bank_ref_num ?? "Not Specified"}`,
    bankcode: `${viewDetails?.bankcode ?? "Not Specified"}`,
    card_type: `${viewDetails?.card_type ?? "Not Specified"}`,
    cardnum: `${viewDetails?.cardnum ?? "Not Specified"}`,
    cash_back_percentage: `${
      viewDetails?.cash_back_percentage ?? "Not Specified"
    }`,
    deduction_percentage: `${
      viewDetails?.deduction_percentage ?? "Not Specified"
    }`,
    easepayid: `${viewDetails?.easepayid ?? "Not Specified"}`,
    email: `${viewDetails?.email ?? "Not Specified"}`,
    error: `${viewDetails?.error ?? "Not Specified"}`,
    error_message: `${viewDetails?.error_message ?? "Not Specified"}`,
    first_name: `${viewDetails?.first_name ?? "Not Specified"}`,
    furl: `${viewDetails?.furl ?? "Not Specified"}`,
    hash: `${viewDetails?.hash ?? "Not Specified"}`,
    issuing_bank: `${viewDetails?.issuing_bank ?? "Not Specified"}    `,
    key: `${viewDetails?.key ?? "Not Specified"}`,
    merchant_logo: `${viewDetails?.merchant_logo ?? "Not Specified"}`,
    mode: `${viewDetails?.mode ?? "Not Specified"}`,
    name_on_card: `${viewDetails?.name_on_card ?? "Not Specified"}`,
    net_amount_debit: `${viewDetails?.net_amount_debit ?? "Not Specified"}`,
    payment_source: `${viewDetails?.payment_source ?? "Not Specified"}`,
    phone: `${viewDetails?.phone ?? "Not Specified"}`,
    productinfo: `${viewDetails?.productinfo ?? "Not Specified"}`,
    status: `${viewDetails?.status ?? "Not Specified"}`,
    transaction_id: `${viewDetails?.transaction_id ?? "Not Specified"}`,
    udf1: `${viewDetails?.udf1 ?? "Not Specified"}`,
    udf2: `${viewDetails?.udf2 ?? "Not Specified"}`,
    udf3: `${viewDetails?.udf3 ?? "Not Specified"}`,
    udf4: `${viewDetails?.udf4 ?? "Not Specified"}`,
    udf5: `${viewDetails?.udf5 ?? "Not Specified"}`,
    udf6: `${viewDetails?.udf6 ?? "Not Specified"}`,
    udf7: `${viewDetails?.udf7 ?? "Not Specified"}`,
    udf8: `${viewDetails?.udf8 ?? "Not Specified"}`,
    udf9: `${viewDetails?.udf9 ?? "Not Specified"}`,
    udf10: `${viewDetails?.udf10 ?? "Not Specified"}`,
    unmappedstatus: `${viewDetails?.unmappedstatus ?? "Not Specified"}`,
    upi_va: `${viewDetails?.upi_va ?? "Not Specified"}`,
  };

  const paymentDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });
  return { viewDetails, paymentDetails };
};

export default usePaymentDetails;
