import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAxiosInstance } from "../../../../api";
import {
  updateConfig as updateGlobalConfig,
  getMenuPermissions,
  getOrderPermissions,
  getBranchPermissions,
} from "../../../../store/slices/Global";
import {
  getAllPermissions,
  getPermission,
  updateConfig,
} from "../../../../store/slices/Roles/rolesSlice.js";
import { roles } from "../../../../store/queries/roles/index.js";
import { global } from "../../../../store/queries/global/index.js";
import { orders } from "../../../../store/queries/orders/index.js";
import { branches } from "../../../../store/queries/branches/index.js";
import { payment } from "../../../../store/queries/payment/index.js";

const useManageRoles = () => {
  const [params] = useSearchParams();
  const role = params.get("role");
  const update = params.get("update");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.roles);

  const [name, setName] = useState("");
  const [permissionModules, setPermissionModules] = useState({});
  const [error, setError] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    dispatch(
      updateGlobalConfig((state) => {
        state.menuDrawer = {
          show: true,
          backTo: "/configure/role",
          text: "Back to Role",
        };
      })
    );
    if (state.allPermissionsStatus === "idle") {
      dispatch(getAllPermissions());
    }
    if (role) {
      dispatch(getPermission({ id: role }))
        .unwrap()
        .then((r) => {
          if (update === "true") {
            setName(r.data.data.name);
          }
          let obj;
          r.data.data.modules.forEach((m) => {
            obj = { ...obj, [m.module_id]: m.permissions };
          });
          setPermissionModules(obj);
        });
    }

    return () => {
      dispatch(
        updateGlobalConfig((state) => {
          state.menuDrawer = {};
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
    if (error) {
      setError("");
    }
  };

  const handlePermissionChange = (item, moduleId) => {
    if (item.e.target.checked) {
      setPermissionModules((prev) => {
        return {
          ...prev,
          [moduleId]: prev?.[moduleId]
            ? [...prev[moduleId], { id: item.id }]
            : [{ id: item.id }],
        };
      });
    } else {
      setPermissionModules((prev) => {
        return {
          ...prev,
          [moduleId]: prev[moduleId].filter(
            (permissions) => permissions.id !== item.id
          ),
        };
      });
    }
  };

  const handleSave = async () => {
    if (name.length === 0) {
      setError("*Name cannot be empty");
    } else {
      const api = await getAxiosInstance();
      const modules = Object.keys(permissionModules).map((mId) => {
        return { module_id: mId, permissions: permissionModules[mId] };
      });
      const filteredData = modules.filter((obj) => obj.permissions.length > 0);
      if (!update) {
        api
          .post("/admin/roles/create", { name, modules: filteredData })
          .then((r) => {
            if (r.status === 200) {
              dispatch(roles.util.invalidateTags(["roles"]));
              dispatch(global.util.invalidateTags(["basic"]));
              toast.success("Role created successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              navigate("/configure/role");
            } else {
              toast.error("Failed to Create Role");
            }
          })
          .catch((err) => {
            // if (err.response.status === 400) {
            toast.error("Failed to Create Role");
            // }
          });
      } else {
        api
          .put("/admin/roles/update", {
            role_id: role,
            name,
            modules: filteredData,
          })
          .then((r) => {
            if (r.status === 200) {
              toast.success("Role updated successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              dispatch(getPermission({ id: role }));
              dispatch(roles.util.invalidateTags(["roles"]));
              dispatch(global.util.invalidateTags(["basic"]));
              dispatch(orders.util.invalidateTags(["orders", "OrderSummary"]));
              dispatch(branches.util.invalidateTags(["branches"]));
              dispatch(payment.util.invalidateTags(["payment"]));
              navigate("/configure/role");
              dispatch(
                getMenuPermissions({
                  token: localStorage.getItem("USER_ACCESS_TOKEN"),
                })
              );
              dispatch(getOrderPermissions());
              dispatch(getBranchPermissions());
            } else {
              toast.error("Failed to Update Role");
            }
          });
      }
    }
  };

  const handleSelectAll = () => {
    setIsChecked((prev) => !prev);

    state.allPermissions.forEach((module) => {
      module.permissions.forEach((permission) => {
        setPermissionModules(() => {
          return {
            [module._id]: module._id && [{ id: permission.id }],
          };
        });
      });
    });

    const transformedResult = transformResponse(state.allPermissions);
    setPermissionModules(transformedResult);
    let temp = Object.entries(transformedResult).map(([moduleId, items]) => ({
      [moduleId]: items,
    }));

    const transformedData = temp.map((obj) => {
      const [module_id, permissions] = Object.entries(obj)[0];
      return { module_id, permissions };
    });

    const resultObject = {
      [role]: {
        modules: transformedData,
      },
    };

    dispatch(
      updateConfig((state) => {
        state.rolePermissions = resultObject;
      })
    );
  };

  const transformResponse = (data) => {
    const transformedData = {};

    data.forEach((module) => {
      const moduleId = module._id;
      const permissions = module.permissions.map((permission) => ({
        id: permission.id,
      }));

      transformedData[moduleId] = permissions;
    });

    return transformedData;
  };

  return {
    state,
    role,
    update,
    name,
    error,
    handleNameChange,
    handlePermissionChange,
    handleSave,
    isChecked,
    handleSelectAll,
  };
};

export default useManageRoles;
