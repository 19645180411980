import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const useConfigure = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Roles",
      link: "/configure/role",
      icon: (
        <span className="material-symbols-outlined x4">
          {" "}
          workspace_premium{" "}
        </span>
      ),
      active: checkIfActiveRoute("/configure/role", true),
    },
    {
      title: "Products",
      link: "/configure/product",
      icon: (
        <span className="material-symbols-outlined x4">
          {" "}
          currency_exchange{" "}
        </span>
      ),
      active: checkIfActiveRoute("/configure/product", true),
    },
    {
      title: "Travel Purpose",
      link: "/configure/travel-purpose",
      icon: (
        <span className="material-symbols-outlined x4">
          {" "}
          connecting_airports{" "}
        </span>
      ),
      active: checkIfActiveRoute("/configure/travel-purpose", true),
    },
    {
      title: "Delivery Type",
      link: "/configure/delivery-type",
      icon: <span className="material-symbols-outlined x4"> package </span>,
      active: checkIfActiveRoute("/configure/delivery-type", true),
    },
    {
      title: "Staffs",
      link: "/configure/staffs",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/configure/staffs", true),
    },
    {
      title: "Currencies",
      link: "/configure/currency",
      icon: (
        <span className="material-symbols-outlined x4">
          universal_currency_alt
        </span>
      ),
      active: checkIfActiveRoute("/configure/currency", true),
    },
    {
      title: "Callback",
      link: "/configure/callback",
      icon: <span class="material-symbols-outlined x4">support_agent</span>,
      active: checkIfActiveRoute("/configure/callback", true),
    },
    {
      title: "General Enquiry",
      link: "/configure/general-enquiry",
      icon: <span class="material-symbols-outlined x4">contact_support</span>,
      active: checkIfActiveRoute("/configure/general-enquiry", true),
    },
    {
      title: "Contact Us",
      link: "/configure/contact-us",
      icon: <span class="material-symbols-outlined x4">call</span>,
      active: checkIfActiveRoute("/configure/contact-us", true),
    },
    {
      title: "Bank Details",
      link: "/configure/bank-details",
      icon: <span class="material-symbols-outlined x4">account_balance</span>,
      active: checkIfActiveRoute("/configure/bank-details", true),
    },
    {
      title: "Activity Log",
      link: "/configure/activity-log",
      icon: <span class="material-symbols-outlined x4">deployed_code_account</span>,
      active: checkIfActiveRoute("/configure/activity-log", true),
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.flatMap?.((menu) => [
      // menu.name,
      ...menu.permissions?.map((side_menu) => side_menu.menu_name),
    ]);
    return drawerMenu
      .filter((menu) => menus?.includes?.(menu.title))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    // drawerMenu: drawerMenuPermission,
    drawerMenu: drawerMenuPermission,
  };
};

export default useConfigure;
