import {useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/orders/orderSlice.js";
import { useGetActivityLogDataQuery } from "../../../../store/queries/orders/index.js";
import { useParams } from "react-router-dom";

const useActivityLogOrder = () => {
  const dispatch = useDispatch();
  const menuState = useSelector((state) => state.orders);
  const {ID } = useParams();
  const {
    data: mainData = {},
    isFetching,
    isLoading,
  } = useGetActivityLogDataQuery({
    sort_by: menuState?.activity_log_Page.sortBy,
    sort_order: menuState?.activity_log_Page.sortOrder,
    search: menuState?.activity_log_Page.search,
    page_size: menuState?.activity_log_Page.currentPageSize,
    page: menuState?.activity_log_Page.currentPage,
    order_id:ID
  });

  useEffect(() => {
    if (menuState?.activity_log_Page.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.activity_log_Page.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState?.activity_log_Page.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  // const [updateStatus] = useUpdateStatusByIdMutation();

  const handleSort = (label) => {
    if (menuState?.activity_log_Page.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.activity_log_Page.sortOrder = menuState?.activity_log_Page.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.activity_log_Page.sortBy = label;
          state.activity_log_Page.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.activity_log_Page.search = e.target.value;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.activity_log_Page.currentPage = page;
        state.activity_log_Page.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.activity_log_Page.currentPageSize = page_size;
        state.activity_log_Page.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.activity_log_Page.currentPage = 1;
        state.activity_log_Page.currentPageSize = 10;
        state.activity_log_Page.sortBy = "";
        state.activity_log_Page.sortOrder = "";
        state.activity_log_Page.search = "";
      })
    );
  };

  return {
    mainData,
    menuState,
    currentPage: menuState?.activity_log_Page?.currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleClearClick,
  };
};

export default useActivityLogOrder;
