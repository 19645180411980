import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  deleteData,
  updateConfig,
} from "../../../../store/slices/orders/orderSlice";
import { useGetProductListeDataQuery } from "../../../../store/queries/orders";
import { toast } from "react-toastify";
import { updateConfig as updateGlobalConfig } from "../../../../store/slices/Global";
import { useMemo } from "react";

const useOrderDetails = () => {
  const dispatch = useDispatch();
  const { ID } = useParams();

  const productState = useSelector((state) => state.orders);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  useEffect(() => {
    dispatch(
      updateGlobalConfig((state) => {
        state.menuDrawer = {
          show: true,
          backTo: "/admin/orders",
          text: "Back",
        };
      })
    );

    return () => {
      dispatch(
        updateGlobalConfig((state) => {
          state.menuDrawer = {};
        })
      );
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (productState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [productState.clearSelection]);

  const {
    data: mainData,
    isLoading,
    refetch,
  } = useGetProductListeDataQuery({
    order_id: ID ?? localStorage.getItem("product_order_id"),
    search: productState?.searchProduct,
    sort_by: productState.productSortBy,
    product_sort_order: productState.productSortOrder,
    filter: productState.productCurrentFilter,
  });

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      let formData = {
        order_id: ID,
        product_id: selectedItemID[0],
      };
      dispatch(deleteData(formData))
        .unwrap()
        .then((result) => {
          if (result?.success) {
            toast.success(result?.message);
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        })
        .catch((err) => {
          toast.error(err?.data?.["product_id"][0]);
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const handleSort = (label) => {
    if (productState.productSortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.productSortOrder =
            productState.productSortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.productSortBy = label;
          state.productSortOrder = "asc";
        })
      );
    }
  };

  const filters = useMemo(() => {
    if (mainData?.data?.filters) {
      const buttonGroups = mainData?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, mainData]);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.productCurrentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
      //  localStorage.setItem("currentFilter", filter.value);
    } else {
      dispatch(
        updateConfig((state) => {
          state.productCurrentFilter = filter.value;
          state.currentPage = 1;
        })
      );
      //  localStorage.setItem("currentFilter", filter.value);
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.searchProduct = e.target.value;
      })
    );
  };

  return {
    productState,
    mainData,
    showDeleteConfirm,
    paginationOptions,
    activeFilter: productState.productCurrentFilter,
    filters,
    getRow,
    handleDelete,
    handleDeleteAction,
    setShowDeleteConfirm,
    handleSort,
    handleFilter,
    handleSearch,
  };
};

export default useOrderDetails;
