import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { deleteData,updateConfig} from "../../../store/slices/orders/orderSlice";
import { toast } from "react-toastify";
import { updateConfig as updateDetailConfig  } from "../../../store/slices/user/userDetailSlice";
import { useMemo } from "react";
import { useGetUserOrderSummaryQuery } from "../../../store/queries/user";
import { getFormatedDateRange } from "../../../utils/functions/table";

const useUserDetails = () => {
  const dispatch = useDispatch();
  const { userID } = useParams();

  const productState = useSelector((state) => state.userDetail);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: mainData,
    isLoading,
    refetch,
  } = useGetUserOrderSummaryQuery({
    user_id: userID ?? "",
    search: productState?.search,
    sort_by: productState?.sortBy,
    sort_order: productState?.sortOrder,
    filter: productState?.currentFilter,
    page_size:productState?.currentPageSize,
    page:productState?.currentPage
  });

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      created_at: (field, data) => (
        <p className="pro-mb-0">{getFormatedDateRange(data[field])}</p>
      ),
      status: (field, data) => (
        <p
            className={`pro-input lg table-ddl  pro-badge pro-mb-0 ${
              (data[field] === "Pending" && "badge-pending-outline") ||
              (data[field] === "Inprogress" && "badge-inprogress-ouline") ||
              (data[field] === "Completed" &&
                "badge-complete-ouline disabled") ||
              (data[field] === "Cancelled" && "badge-cancelled-ouline") ||
              (data[field] === "Incomplete" && "badge-incomplete-outline")
            } `}
          >
            {data[field]}
          </p>
      ),
      // payment_status: (field, data) => (
      //   <p
      //       className={`pro-input lg table-ddl  pro-badge pro-mb-0 ${
      //         (data[field] === "pending" && "badge-pending-outline") ||
      //         (data[field] === "Inprogress" && "badge-inprogress-ouline") ||
      //         (data[field] === "completed" &&
      //           "badge-complete-ouline disabled") ||
      //         (data[field] === "Cancelled" && "badge-cancelled-ouline") ||
      //         (data[field] === "Incomplete" && "badge-incomplete-outline")
      //       } `}
      //     >
      //       {data[field]}
      //     </p>
      // ),
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };
  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      let formData = {
        order_id: userID,
        product_id: selectedItemID[0],
      };
      dispatch(deleteData(formData))
        .unwrap()
        .then((result) => {
          if (result?.success) {
            toast.success(result?.message);
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        })
        .catch((err) => {
          toast.error(err?.data?.["product_id"][0]);
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const handleSort = (label) => {
    if (productState.sortBy === label) {
      dispatch(
        updateDetailConfig((state) => {
          state.sortOrder =
            productState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateDetailConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const filters = useMemo(() => {
    if (mainData?.data?.filters) {
      const buttonGroups = mainData?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, mainData]);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateDetailConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "desc";
          state.currentPage = 1;
          state.search = ""
          state.currentPageSize = 10
        })
      );
      //  localStorage.setItem("currentFilter", filter.value);
    } else {
      dispatch(
        updateDetailConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "desc";
          state.currentPage = 1;
          state.search = ""
          state.currentPageSize = 10
        })
      );
      //  localStorage.setItem("currentFilter", filter.value);
    }
  };

  const handleSearch = (e) => {
    dispatch(
        updateDetailConfig((state) => {
        state.search = e.target.value;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(
        updateDetailConfig((state) => {
          state.currentFilter = null;
          state.sortBy = "";
          state.sortOrder = "desc";
          state.currentPage = 1;
          state.search = ""
          state.currentPageSize = 10
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateDetailConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };
  return {
    productState,
    mainData,
    showDeleteConfirm,
    paginationOptions,
    activeFilter: productState?.currentFilter,
    filters,
    getRow,
    handleDelete,
    handleDeleteAction,
    setShowDeleteConfirm,
    handleSort,
    handleFilter,
    handleSearch,
    handleClearClick,
    handlePagination
  };
};

export default useUserDetails;
