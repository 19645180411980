import React from "react";
import usePaymentDetailsOrder from "./usePaymentDetailsOrder";
import OverviewShimmer from "../../../Global/Shimmers/OverviewShimmer";
import { MultiColumnTable } from "@wac-ui-dashboard/wac_component_library";
import Style from "./paymentDetailsOrder.module.scss";

const PaymentDetailsOrder = () => {
  const { paymentValues, isFetching,hasSendLinkPermission,paymentDetailData,handleSendLink} = usePaymentDetailsOrder();
  return (
    <div className={Style.overview_main}>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <>
          <div className="pro-py-5">
            <MultiColumnTable
                propStyle={{
                  root_header : `pro-d-flex pro-items-center pro-gap-3`,
                  root_Icon : `pro-btn-primary pro-rounded-2 ${Style.sent_btn}`
                }}
              minWidth={`30%`}
              title={"Payment Details"}
              data={paymentValues}
              extraClassNames={`${Style.table_wrap} ""`}
              editIcon={(hasSendLinkPermission===1 && paymentDetailData?.data?.share_payment_link)?"Sent Link":""}
              handleEdit={()=>(handleSendLink())}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentDetailsOrder;
