import {
  ModalLayout,
  Pagination,
  Table,
  ConfirmationBox,
  SearchFilters,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import useOrderDetails from "./useOrderDetails";
import EmptyData from "../../../Global/EmptyData";

const OrderDetails = () => {
  const {
    mainData,
    productState,
    currentPage,
    paginationOptions,
    isFetching,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    handleDelete,
    showDeleteConfirm,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleFilter,
    activeFilter,
    filters,
    handleSearch,
  } = useOrderDetails();
  return (
    <>
      <div className={`col-auto pro-pt-5 pro-pb-6`}>
        <h6 className="pro-ttl h6 pro-mb-3">Product Details</h6>
        <div className="row">
          <div className="col">
            <SearchFilters
              data={filters}
              activeFilter={activeFilter}
              handleButtonGroupChange={handleFilter}
              showActions={false}
              onSearchInput={handleSearch}
              searchInputProps={{ value: productState?.searchProduct }}
            />
          </div>
        </div>
        {mainData?.data?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={mainData?.data?.data?.data || []}
            uniqueID={"product_id"}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            editable={false}
            showCheckBox={true}
            deletable={true}
            handleDelete={handleDelete}
            multiSelect={false}
            assignable={false}
            clear={productState?.clearSelection}
            fields={mainData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={productState?.currentPageSize}
          />
        )}

        {mainData?.data?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === productState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.data?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default OrderDetails;
