import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentPage: 1,
    currentFilter: null,
    currentPageSize: 10,
    sortBy: "",
    sortOrder: "desc",
    search: "",
}

const userDetailSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = userDetailSlice.actions;
export default userDetailSlice.reducer;
