import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  useGetUserListDataQuery,
  useUpdateUserStatusMutation,
  useUpdateTableFieldsDataMutation,
} from "../../../store/queries/user";
import { updateConfig } from "../../../store/slices/user/userSlice";
import { addDays } from "date-fns";
import { useState, useMemo, useEffect } from "react";
import { useGetBasicDataQuery } from "../../../store/queries/global";
import { getUserEditData } from "../../../store/slices/user/userSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { user } from "../../../store/queries/user";
import { current } from "@reduxjs/toolkit";
import Assets from "../../../assets/Assets";
import { IconText, Image } from "@wac-ui-dashboard/wac_component_library";
import Style from "./user.module.scss";
import { useNavigate } from "react-router-dom";
import { updateConfig as updateDetailConfig } from "../../../store/slices/user/userDetailSlice";
import { getOrderBasicData } from "../../../store/slices/OrderCreate/orderCreateSlice";
const useUser = ({ dashboard }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuState = useSelector((state) => state.user);
  const { showEditModal } = useSelector((state) => state.global);
  const [updateUserStatus] = useUpdateUserStatusMutation();
  const { showCreateModal } = useSelector((state) => state.user);
  const [updateTableFields] = useUpdateTableFieldsDataMutation();
  const { data: formData = {} } = useGetBasicDataQuery();

  const {
    data: mainData = {},
    isFetching,
    isLoading,
    refetch,
  } = useGetUserListDataQuery({
    sort_by: menuState.sortBy,
    sort_order: dashboard ? "desc" : menuState.sortOrder,
    search: menuState.search,
    per_page: dashboard ? 10 : menuState.currentPageSize,
    page: dashboard ? 1 : menuState?.currentPage,
    filter: dashboard ? null : menuState?.currentFilter,
    start: getFormatedDate(menuState?.start),
    end: getFormatedDate(menuState?.end),
  });

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: menuState.start,
    endDate: menuState.end,
    key: "selection",
  });
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  //clearing all the slice states initially
  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.scrollPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.currentBranchFilter = [];
        state.clearSelection = false;
        state.queryData = {};
        state.queryStatus = "idle";
        state.sortBy = "";
        state.sortOrder = "desc";
        state.search = "";
        state.showCreateModal = false;
        state.selectedId = "";
        state.selectedItemsDetails = "";
        state.is_edit = false;
        state.showViewModal = false;
      })
    );
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [menuState.clearSelection]);

  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = false;
        state.selectedItemsDetails = "";
      })
    );
    dispatch(getOrderBasicData());
  };
  let actionOptions = [{ label: "Edit Columns", value: 0 }];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
      })
    );
  };

  const hasEditPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasViewPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasChangeStatusPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_change_status")
    );
    return permission?.[0]?.can_change_status ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasCreatePermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
    // eslint-disable-next-line
  }, [mainData]);
  const hasUserViewPermission = useMemo(() => {
    let permission = mainData?.data?.permission?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const handleClearClickDetail = () => {
    dispatch(
      updateDetailConfig((state) => {
        state.currentFilter = null;
        state.sortBy = "";
        state.sortOrder = "desc";
        state.currentPage = 1;
        state.search = "";
        state.currentPageSize = 10;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      name: (feild, data) => {
        return (
          <>
            {hasUserViewPermission === 1 ? (
              <div className="text-truncate icontxt_no_hover pro-pnt">
                <IconText
                  icon={
                    <img
                      src={data[field].profile_url ?? Assets.GENDER_IMAGE}
                      alt={data[field].name}
                      width={20}
                      height={20}
                      className="object-fit-cover"
                    />
                  }
                  onClick={() => {
                    handleClearClickDetail();
                    navigate({
                      pathname: `/user/user-details/${data?.user_id}`,
                    });
                  }}
                  title={data[field].name}
                  propStyle={{
                    rootIcon:
                      "pro-d-flex pro-items-center pro-rounded-circle pro-overflow-hidden ",
                  }}
                />
              </div>
            ) : (
              <div className={`pro-d-flex ${Style.avatar_container}`}>
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={data[field].profile_url ?? Assets.GENDER_IMAGE}
                  width={16}
                  height={16}
                  alt={data[field].name}
                />
              </div>
              <span className="pro-ms-1 pro-align-self-center">
                {data[field].name}
              </span>
            </div>
            )}
          </>
        );
      },
      status: (field, data) => {
        const isChecked = data?.status;
        return (
          <div className="pro-toggle">
            <div className="pro-toggle-box">
              <input
                id="status"
                name="status"
                type="checkbox"
                checked={isChecked}
                onChange={
                  hasChangeStatusPermission
                    ? () => toggleStatusById(data?.status, data?.user_id)
                    : undefined
                }
                disabled={!hasChangeStatusPermission || dashboard}
              />
              <span></span>
            </div>
            {hasChangeStatusPermission === 0 ? <label>Status</label> : null}
          </div>
        );
      },
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const toggleStatusById = (status, id) => {
    let formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("user_id", id);
    formData.append("status", status === 1 ? 0 : 1);

    updateUserStatus(formData);
    dispatch(
      user.util.updateQueryData(
        "getUserListData",
        {
          sort_by: menuState.sortBy,
          sort_order: menuState.sortOrder,
          search: menuState.search,
          per_page: menuState.currentPageSize,
          page: menuState?.currentPage,
          filter: menuState?.currentFilter,
          start: getFormatedDate(menuState?.start),
          end: getFormatedDate(menuState?.end),
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache.data.data?.data?.map((res) =>
            res.user_id === id
              ? {
                  ...res,
                  status: status === 1 ? 0 : 1,
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              data: {
                ...currentCache.data.data,
                data: newResult,
              },
            },
          };
          return currentCache;
        }
      )
    );
  };

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditAction = (data) => {
    dispatch(getUserEditData(data?.[0]));
    dispatch(getOrderBasicData());
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
        state.selectedId = data?.[0];
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
        state.clearSelection = true;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        state.userEditData = {};
        // state.selectedItemsDetails = ""
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };
  const filters = useMemo(() => {
    if (mainData?.data?.filters) {
      const buttonGroups = mainData?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading, mainData]);

  const handleFilter = (filter) => {
    if (filter.value === null) {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.start = startDate;
        state.end = endDate;
      })
    );
  };

  const handleDashboardRedirect = () => {
    navigate("/user");
    dispatch(
      updateConfig((state) => {
        if (dashboard) {
          state.currentFilter = null;
          state.currentPage = 1;
        } else {
          state.currentPage = 1;
        }
      })
    );
    // }, 500);
  };

  return {
    mainData,
    isLoading,
    isFetching,
    hasEditPermission,
    hasViewPermission,
    menuState,
    paginationOptions,
    actionOptions,
    currentPage: menuState?.currentPage,
    activeFilter: menuState?.currentFilter,
    formData,
    showCreateModal,
    showEditModal,
    date,
    filters,
    hasCreatePermission,
    handleDashboardRedirect,
    handleDateRangeChange,
    handleFilter,
    updateTableFields,
    closeEditModal,
    handleSearch,
    handleEditClick,
    handleActionChange,
    handleClearClick,
    getRow,
    handleSort,
    handleEditAction,
    handlePagination,
    handlePageSize,
    closeModal,
    refetch,
    handleCreateClick,
  };
};

export default useUser;
