import { useRef, useState } from "react";
import { useParams } from "react-router-dom/dist";
import { useGetUserViewDataQuery } from "../../../store/queries/user";

const useUserDetailLayout = () => {

  const printRef = useRef();
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [show, setShow] = useState(false);
  const userData = JSON.parse(localStorage?.getItem("userDetails"));
  const { orderID, userID } = useParams();
  const activeProfile = orderID ?? sessionStorage.getItem("active");

  const { data: userView, isFetching } = useGetUserViewDataQuery({
    user_id: userID,
  });
  const label = {
    user_id: "User ID",
    name: "Name",
    email: "Email",
    phone: "Phone",
    dob: "DOB",
    country:"Country",
    state:"State",
    district:"District",
  };
  const value = {
    user_id: `${userView?.data?.user_unique_id ?? "Not specified"}` ,
    name: `${userView?.data?.name ?? "Not specified"}`,
    email: `${userView?.data?.email ?? "Not specified"}`,
    phone: `${
        userView?.data?.phone_number
        ? `${userView?.data?.country_code} ${userView?.data?.phone_number}`
        : "Not specified"
    }`,
    country: `${userView?.data?.country?.name ?? "Not specified"}`,   
    state: `${userView?.data?.state?.name ?? "Not specified"}`,
    district: `${userView?.data?.district?.name ?? "Not specified"}`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  return {
    show,
    activeProfile,
    isFetching,
    printRef,
    // profileData,
    basicDetails,
    showDeleteImageModal,
    userData,
    // orderSummary,
    setShowDeleteImageModal,
    setShow,
    userView,
    // refetch,
  };
};

export default useUserDetailLayout;
