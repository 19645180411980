import { useSelector, useDispatch } from "react-redux";
import { useGetBankDetailsDataQuery } from "../../../store/queries/bankDetails";
import { updateConfig } from "../../../store/slices/BankDetails/bankDetailsSlice";
import { viewBankAccountDetails } from "./.api";

const useBankDetails = () => {
  const dispatch = useDispatch();
  const bankDetailsState = useSelector((state) => state.bankDetails);

  const {
    data: bankDetailsData = {},
    isFetching,
    refetch,
  } = useGetBankDetailsDataQuery();

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.is_edit = false;
        state.showCreateModal = true;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleEditAction = (data) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
      })
    );
    viewBankAccountDetails({ bank_account_id: data?.[0] }).then((res) => {
      dispatch(
        updateConfig((state) => {
          state.selectedItemsDetails = res?.data?.data;
        })
      );
    });
  };

  return {
    isFetching,
    bankDetailsData,
    bankDetailsState,
    handleEditAction,
    refetch,
    closeModal,
    handleCreateClick,
    getRow,
  };
};

export default useBankDetails;
