import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    const branchIdParams = params?.branch_id
      ? params.branch_id.map((id) => `branch_id[]=${id}`).join("&")
      : "";

    try {
      const response = await api[method](
        `${endpoint}?${getParams("order_id")}${getParams("start")}${getParams(
          "end"
        )}${getParams("filter")}${getParams("sort_by")}${getParams(
          "product_sort_by"
        )}is_active=${params?.is_active ? params["is_active"] : 0}&sort_order=${
          params?.sort_order
            ? params?.sort_order
            : (params?.product_sort_order
                ? params?.product_sort_order
                : "desc") || "desc"
        }&page_size=${params?.page_size || "10"}&${getParams(
          "search"
        )}&${branchIdParams}&page=${params?.page || 1}&${getParams(
          "main_status"
        )}&${getParams("sub_status")}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const ordersCreate = createApi({
  reducerPath: "ordersCreateApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "orders",
    "product",
    "document",
    "travel",
    "delivery",
    "payment",
    "OrderNoteHistory",
    "OrderSummary",
    "PaymentSummary",
    "userList"
  ],
  endpoints: (builder) => ({
    getOrderListeData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `admin/orders`,
      }),
      providesTags: ["orders"],
    }),
    getUserData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/order/basic/fetch-user`,
      }),
      invalidatesTags: ["userList"],
    }),
  }),
});

export const {
  useGetOrderListeDataQuery,
  useGetUserDataQuery
} = ordersCreate;
