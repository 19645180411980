import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getNotificationData,
  updateConfig,
} from "../../../store/slices/Notifications/notificationSlice";
import { useSelector } from "react-redux";
import useObserver from "../../../utils/hooks/useObserver";

const useNotificationListing = () => {
  const [showScroll, setShowScroll] = useState(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const state = useSelector((state) => state.notification);
  const notificationState = state?.notificationData?.["notificationData"];

  useEffect(() => {
    if (Object.keys(state?.notificationData)?.length === 0) {
      dispatch(
        getNotificationData({
          page: page,
          notification_id: "notificationData",
        })
      );
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    function handleWindowResize() {
      if (window.scrollY > 150) {
        setShowScroll(true);
      } else if (window.scrollY === 0) {
        setShowScroll(false);
      }
    }
    window.addEventListener("scroll", handleWindowResize);

    return () => {
      window.removeEventListener("scroll", handleWindowResize);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handleLoadMore = () => {
    dispatch(
      updateConfig((state) => {
        // state.currentPage = state.currentPage + 1;
        state.notificationData["notificationData"].page += 1;
      })
    );
    setPage((prev) => prev + 1);
    dispatch(
      getNotificationData({
        page: page + 1,
        notification_id: "notificationData",
      })
    );
  };

  const { lastElement } = useObserver({
    loading: state?.notificationStatus === "pending",
    hasMore:
      notificationState?.page_size * notificationState?.page <
      notificationState?.total_count,
    callback: handleLoadMore,
  });

  return {
    showScroll,
    state,
    notificationState,
    lastElement,
    handleScrollToTop,
  };
};

export default useNotificationListing;
