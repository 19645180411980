import { getAxiosInstance } from "../../../../api";

export const getPaymentDetailData = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `admin/payments/view?payment_id=${params.payment_id}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
