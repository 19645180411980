import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";

const ReasonModal = ({
  statusReason,
  setStatusReason,
  isStickyFooter,
  closeModal,
  handleSubmitStatusChange,
  errors,
}) => {
  return (
    <>
      <div className="pro-m-3">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Comment*
        </label>
        <textarea
          type="text"
          id="closing_reason"
          rows={4}
          cols={50}
          name="account_number"
          className={`pro-input lg ${
            errors && statusReason?.length <= 0 && "error"
          }`}
          value={statusReason}
          onChange={(e) => setStatusReason(e.target.value)}
        />
        {statusReason?.length <= 0 && (
          <span className="error-text">{errors}</span>
        )}
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={handleSubmitStatusChange}
        >
          {"Submit"}
        </Button>
      </div>
    </>
  );
};

export default ReasonModal;
