import { getAxiosInstance } from "../../../../../api";

export const createDeliveryDetails = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/delivery/details/create`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateDeliveryDetails = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/service-providers/update`, body, {
      headers: {
        'X-HTTP-Method-Override': 'PUT'
      }
    })
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const pincodeVerify = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/delivery/details/pincode/validation`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};