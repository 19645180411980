import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React  from "react";
import Select from "react-select";
import useProductDetails from "./useProductDetails";
import OrderDetails from "./OrderDetails";
import { AsyncPaginate } from 'react-select-async-paginate';

const ProductDetails = ({
  // formik,
  // refetch,
  handleClose,
  hideStep,
  setHideStep
}) => {
  const {  
    formik,
    isEdit,
    getFieldError,
    handleBranchChange,
    handleAddOrder,
    // userList,
    orderBasicData,
    orderCreateState,
    basicData,
    loadOptions} = useProductDetails({
      hideStep
    // refetch,
    // setShowform
  });
  return (
    <div className="col">
      <div className={`pro-mb-4`}>
        <div className="row">
          <div className="col-auto">
            <div className="pro-check-box radio">
              <input
                className="pro-check"
                type="radio"
                name="radio_type"
                id="buy_forex"
                checked={formik?.values?.type === 1}
                onChange={() => {         
                  formik.resetForm();
                  formik.setFieldValue("type", 1);
                  formik.setFieldValue("branch_id", "");
                  setHideStep(false)
                }}
                disabled={orderCreateState?.EditCreate.productDetails}
              />
              <label className="pro-check-label" htmlFor="buy_forex">
                Buy Forex
              </label>
            </div>
          </div>
          <div className="col-auto">
            <div className="pro-check-box radio">
              <input
                className="pro-check"
                type="radio"
                name="radio_type"
                id="sell_forex"
                checked={formik?.values?.type === 2}
                onChange={() => {
                  formik.resetForm();
                  formik.setFieldValue("type", 2);
                  formik.setFieldValue("branch_id", "");
                  setHideStep(true)
                }}
                disabled={orderCreateState?.EditCreate.productDetails}
              />
              <label className="pro-check-label" htmlFor="sell_forex">
                 Sell Forex
              </label>
            </div>
          </div>
        </div>
      </div>
       {/* <div className="pro-mb-4">
        <label
          htmlFor="user_id"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          User
        </label>
        <Select
          id="user_id"
          placeholder={"User"}
          className={`pro-input lg  ${getFieldError("user_id") && " error"}`}
          classNamePrefix="pro-input"
          name="user_id"
          options={userList?.data ?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={userList?.data?.find((e)=>(e?._id===formik?.values?.user_id))}
          onBlur={formik.handleBlur("user_id")}
          onChange={(value) => {formik.setFieldValue("user_id", value?._id ?? "")}}
          menuPlacement="auto"
          isClearable
          isSearchable={true} 
          onInputChange={(e, { action }) => {
            if (action === "input-change") {
              handleInputSearch(e)
              // Perform actions like updating a state or filtering options if needed
            }
          }}
          isDisabled={isEdit || orderCreateState?.EditCreate.productDetails}
        />
        {getFieldError("user_id") && (
          <span className="error-text">{getFieldError("user_id")}</span>
        )}
      </div> */}
         <div className="pro-mb-4">
        <label
          htmlFor="user_id"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          User
        </label>
        <AsyncPaginate
            className={`pro-input lg  ${getFieldError("user_id") && " error"}`}
            classNamePrefix="pro-input"
            value={formik?.values?.user_id}
            loadOptions={loadOptions}
            additional={{ page: 1 }}
            placeholder="Select User"
            isLoading={orderCreateState?.userData?.userStatus === "pending"}
            onChange={(value) => {formik.setFieldValue("user_id", value ?? "")}}
            getOptionValue={(option) => option?._id}
            getOptionLabel={(option) => option?.name}
            isDisabled={isEdit || orderCreateState?.EditCreate.productDetails}
          />
        {getFieldError("user_id") && (
          <span className="error-text">{getFieldError("user_id")}</span>
        )}
      </div>
      <div className="pro-mb-4">
        <label
          htmlFor="branch_id"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Branch
        </label>
        <Select
          id="branch_id"
          placeholder={"Branch"}
          className={`pro-input lg  ${getFieldError("branch_id") && " error"}`}
          classNamePrefix="pro-input"
          name="branch_id"
          options={orderBasicData?.branches ?? []}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={orderBasicData?.branches?.find((e)=>(e?._id===formik?.values?.branch_id))??""}
          onBlur={formik.handleBlur("branch_id")}
          onChange={(value) => handleBranchChange(value?._id)}
          menuPlacement="auto"
          isClearable
        />
        {getFieldError("branch_id") && (
          <span className="error-text">{getFieldError("branch_id")}</span>
        )}
      </div>
      <div className="pro-mb-4">
          {formik?.values?.order_details?.map((value, index) => (
              <OrderDetails
                formik={formik}
                values={value}
                key={index}
                getFieldError={getFieldError}
                itmIndex={index}
                basicData={basicData}
                currencyData={orderCreateState?.currencyData??[]}
              />
          ))}
        <button
          className={"pro-btn-link pro-mt-3 pro-items-center lg"}
          onClick={()=>{handleAddOrder()}}
          type="button"
          // disabled={
          //   (formik?.values?.order_details?.length ===
          //     orderCreateState?.currencyData?.currency_list?.length ||
          //     (formik?.errors?.order_details &&
          //       formik?.errors?.order_details?.length !== 0)) &&
          //   true
          // }
        >
          <span className="material-symbols-outlined">add_circle</span>{" "}
          <span>Add More Products</span>
        </button>
      </div>
      <div className="input-wrap">
        <Input
          label={"Forex Amount"}
          type="text"
          id="total_amount"
          name="total_amount"
          className={`pro-input lg ${getFieldError("total_amount") && " error"}`}
          {...formik.getFieldProps("total_amount")}
          error={getFieldError("total_amount")}
          errorMessage={getFieldError("total_amount")}
          disabled={true}
        />
      </div>
      <div className={`pro-mb-4`}>
        <div className="row">
          <div className="col-auto">
            <div className="pro-check-box radio">
              <input
                className="pro-check"
                type="radio"
                name="citizen_type"
                id="resident_of_india"
                checked={formik?.values?.citizen_type === 1}
                onChange={() => formik.setFieldValue("citizen_type", 1)}
              />
              <label className="pro-check-label" htmlFor="resident_of_india">
              Resident of India
              </label>
            </div>
          </div>
          {hideStep&&(<><div className="col-auto">
            <div className="pro-check-box radio">
              <input
                className="pro-check"
                type="radio"
                name="citizen_type"
                id="nri"
                checked={formik?.values?.citizen_type === 2}
                onChange={() => formik.setFieldValue("citizen_type", 2)}
              />
              <label className="pro-check-label" htmlFor="nri">
                NRI
              </label>
            </div>
          </div>
          <div className="col-auto">
            <div className="pro-check-box radio">
              <input
                className="pro-check"
                type="radio"
                name="citizen_type"
                id="foreigner"
                checked={formik?.values?.citizen_type === 3}
                onChange={() => formik.setFieldValue("citizen_type", 3)}
              />
              <label className="pro-check-label" htmlFor="foreigner">
              Foreigner
              </label>
            </div>
          </div></>)}
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={()=>handleClose()}
          type="button"
        >
          Cancel
        </Button>

        <Button
          type="submit"
          className={`pro-btn-primary lg pro-ms-3 pro-px-5 ${formik.isSubmitting ? "loading" : ""}`}
          disabled={formik.isSubmitting}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
           Next
        </Button>
      </div>
    </div>
  );
};

export default ProductDetails;
