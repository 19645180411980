import React from "react";
import Style from "./deliveryDetails.module.scss";
import { MultiColumnTable } from "@wac-ui-dashboard/wac_component_library";
import useDeliveryDetails from "./useDeliveryDetails";
import OverviewShimmer from "../../../Global/Shimmers/OverviewShimmer";

const DeliveryDetails = () => {
  const { deliveryDetails, isFetching } = useDeliveryDetails();

  return (
    <div className={Style.overview_main}>
      {isFetching ? (
        <div className="pro-p-4 pro-w-100">
          <OverviewShimmer />
        </div>
      ) : (
        <>
          <div className="pro-py-4">
            <MultiColumnTable
              minWidth={`30%`}
              title={"Delivery Details"}
              data={deliveryDetails}
              extraClassNames={`${Style.table_wrap} pro-pb-5`}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DeliveryDetails;
