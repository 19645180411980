import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "../api";
import useValidations from "../../../utils/hooks/useValidations";
import useRoutes from "../../../routes/useRoutes";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Global";

const useAuth = (emailRef) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { validateEmail } = useValidations();

  const [loginStatus, setLoginStatus] = useState("idle");
  const [loginInfo, setLoginInfo] = useState({});
  const [showPassword, setShowPassword] = useState(true);
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState("idle");
  const { allowedDashboardComponent } = useRoutes();

  useEffect(() => {
    if (loginStatus === "success") {
      dispatch(
        updateConfig((state) => {
          state.isLogged = true;
        })
      );
      navigate(`/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`);
    } else if (loginStatus === "failed") {
      let errorFields = Object.keys(loginInfo);
      errorFields.forEach((field) => {
        formik.setFieldError(field, loginInfo[field]);
      });
    }
    // eslint-disable-next-line
  }, [loginStatus]);

  const formik = useFormik({
    initialValues: {
      email_or_phone_number: "",
      password: "",
      login_type: 1,
    },
    validate: (values) => {
      let errors = {};
      if (!values.email_or_phone_number) {
        errors.email_or_phone_number = "*User Name Required";
      }
      if (!values.password) {
        errors.password = "*Password Required";
      }
      if (!validateEmail(values.email_or_phone_number)) {
        errors.email_or_phone_number = "*Enter a valid email";
      }
      return errors;
    },
    onSubmit: (values) => {
      emailRef.current = values.email_or_phone_number;
      let obj = {
        email_or_phone_number: values?.email_or_phone_number,
        password: values?.password,
        login_type: 1,
      };
      setLoginStatus("pending");

      login(obj).then((response) => {
        if (response.data.success) {
          localStorage.setItem(
            "USER_DETAILS",
            JSON.stringify(response.data.data)
          );

          localStorage.setItem(
            "USER_ACCESS_TOKEN",
            response?.data?.data?.token
          );
          setLoginStatus("success");
        } else {
          if (response?.message !== "") {
            setLoginStatus("failed");
            setLoginInfo({
              password: response?.message,
            });
          } else {
            setLoginStatus("failed");
            setLoginInfo({
              password:
                "An error occurred during login. Please try again later.",
            });
          }
        }
      });
    },
  });

  const handleForgotPassword = () => {
    navigate(`/login/forgot-password/capture-email`);
  };

  const handleShowPassword = (e) => {
    e.preventDefault();

    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  return {
    formik,
    loginStatus,
    forgotPasswordStatus,
    showPassword,
    handleShowPassword,
    setForgotPasswordStatus,
    handleForgotPassword,
  };
};

export default useAuth;
