import {
  HeadingGroup,
  OffCanvasLayout,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useBankDetails from "./useBankDetails";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import BankDetailsForm from "./bankDetaildForm";

const BankDetails = () => {
  const {
    bankDetailsData,
    isFetching,
    bankDetailsState,
    handleEditAction,
    refetch,
    closeModal,
    handleCreateClick,
    getRow,
  } = useBankDetails();

  return (
    <>
      <HeadingGroup
        title={"Bank Details"}
        className={`pro-mb-4`}
        buttonTitle={bankDetailsData?.data?.data?.length === 0 ? "Add new" : ""}
        handleClick={handleCreateClick}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {bankDetailsData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={bankDetailsData?.data?.data || []}
            uniqueID={"id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            showCheckBox={true}
            deletable={false}
            handleEdit={handleEditAction}
            multiSelect={false}
            assignable={false}
            fields={bankDetailsData?.data?.fields}
            SortIcon={<FaSort />}
            getRow={getRow}
            loading={isFetching}
          />
        )}
      </div>

      <OffCanvasLayout
        show={bankDetailsState.showCreateModal}
        handleClose={closeModal}
        title={
          bankDetailsState?.is_edit ? "Update Bank Account" : "Add Bank Account"
        }
        closeIcon={<span className="material-symbols-outlined">close</span>}
        backdrop="static"
      >
        <BankDetailsForm refetch={refetch} closeModal={closeModal} />
      </OffCanvasLayout>
    </>
  );
};

export default BankDetails;
