import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  OffCanvasLayout,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useActivityLog from "./useActivityLog";
import EmptyData from "../../Global/EmptyData";
import { useEffect, useState } from "react";

const ActivityLog = () => {
  const {
    mainData,
    menuState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    hasCreatePermission,
    hasDeletePermission,
    showEditModal,
    actionOptions,
    refetch,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    closeEditModal,
    handleClearClick,
    updateTableFields,
    handleCreateClick,
    handleEditAction,
    handleActionChange,
    checkIsActive,
  } = useActivityLog();

  const [totalPageCount, setTotalPageCount] = useState(0);

  useEffect(() => {
    if (mainData?.data?.total_count) {
      const pageCount = Math.ceil(
        mainData?.data?.total_count / menuState?.currentPageSize
      );
      setTotalPageCount(pageCount);
    }
    //eslint-disable-next-line
  }, [mainData?.data]);
  return (
    <>
      <HeadingGroup
        title={"Activity Log"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission === 1 ? "Add new" : ""}
        handleClick={handleCreateClick}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <div className="row">
          <div className="col">
            <SearchFilters
              data={mainData?.data?.filters}
              onSearchInput={handleSearch}
              showActions={false}
              handleActionClick={handleEditClick}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              actionOptions={actionOptions?.filter((item) => item.value === 0)}
              onActionOptionChange={handleActionChange}
              handleClear={handleClearClick}
              searchInputProps={{ value: menuState?.search }}
            />
          </div>
        </div>

        {mainData?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={mainData?.data?.data || []}
            uniqueID={"_id"}
            showCheckBox={false}
            deletable={false}
            editable={false}
            clear={menuState.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={mainData?.data?.fields}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={menuState?.currentPageSize}
            handleSelect={checkIsActive}
          />
        
        )}

        {mainData?.data?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={totalPageCount}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout show={showEditModal} handleClose={closeEditModal} backdrop="static">
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.fields ?? {})
                .reduce((filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.fields[property];
                  return filteredObj;
                }, {})}
              moduleId={mainData?.data?.module_id}
              updateData={updateTableFields}
            />
          </div>
        </ModalLayout>

        {/* <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout> */}
      </div>
    </>
  );
};

export default ActivityLog;
