import { getAxiosInstance } from "../../../../../api";

export const createTravelDetails = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/orders/add/travel-details`, body)
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateTravelDetails = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/orders/update/travel-details`, body)
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const viewProviders = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/service-providers/edit?service_provider_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};