import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CreateBankAccount, UpdateBankAccount } from "../.api";

const useBankDetailsForm = ({ refetch, closeModal }) => {
  const { selectedItemsDetails, is_edit } = useSelector(
    (state) => state.bankDetails
  );

  const validation = Yup.object({
    account_holder_name: Yup.string()
      .min(2, "The name must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .required("*Account holder name is required"),
    account_number: Yup.number()
      .required("*Account number required")
      .min(2, "The number must be at least 2 characters"),
    confirm_account_number: Yup.number()
      .required("*Account number required")
      .required("*Confirm account number required")
      .test(
        "account-numbers-match",
        "*Account numbers must match",
        function (value) {
          return +formik?.values?.account_number === +value;
        }
      ),
    ifsc_code: Yup.string()
      .required("*Ifsc code is required")
      .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "*Invalid IFSC code format "),
    branch: Yup.string().required("*Branch is required"),
    bank_name: Yup.string().required("*Bank name is required"),
  });

  const formik = useFormik({
    initialValues: {
      account_holder_name: is_edit
        ? selectedItemsDetails?.account_holder_name
        : "",
      account_number: is_edit ? selectedItemsDetails?.account_number : "",
      confirm_account_number: is_edit
        ? selectedItemsDetails?.account_number
        : "",
      ifsc_code: is_edit ? selectedItemsDetails?.ifsc_code : "",
      branch: is_edit ? selectedItemsDetails?.branch : "",
      bank_name: is_edit ? selectedItemsDetails?.bank_name : "",
    },

    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        account_holder_name: values?.account_holder_name,
        account_number: values?.account_number,
        confirm_account_number: values?.confirm_account_number,
        ifsc_code: values?.ifsc_code,
        branch: values?.branch,
        bank_name: values?.bank_name,
      };

      if (is_edit) {
        obj.bank_account_id = selectedItemsDetails?.id;
      }

      if (selectedItemsDetails !== "") {
        UpdateBankAccount(obj).then((res) => {
          if (res.status === 200) {
            closeModal();
            refetch();
            toast.success(
              res?.data?.message ?? "Bank account details successfully updated "
            );
          } else {
            closeModal();
            toast.error("Failed to update bank account details");
          }
        });
      } else {
        CreateBankAccount(obj).then((res) => {
          if (res.status === 200) {
            closeModal();
            refetch();
            toast.success(
              res?.data?.message ?? "Bank account details added succesfully"
            );
          } else {
            closeModal();
            toast.error("Failed to add bank account");
          }
        });
      }
    },
  });

  return { formik, is_edit };
};

export default useBankDetailsForm;
